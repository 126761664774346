a {
  text-decoration: unset;
}

.marquee-container {
  width: 100%; /* Chiều rộng của vùng nhìn thấy */
  overflow: hidden; /* Ẩn phần chữ ra khỏi container */
  white-space: nowrap; /* Chữ nằm trên một dòng */
}

.marquee {
  display: flex;
}

.marquee > h1 {
  padding-right: 70px;
}