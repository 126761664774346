@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&amp;family=Outfit:wght@200;300;400;500;600;700;800&amp;family=Lora:wght@400;500;600;700&amp;family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&amp;display=swap");
.bg-image {
  position: relative;
  background-position: center center;
  background-attachment: scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.bg-video video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.bg-pattern-diagonal {
  position: relative;
  overflow: hidden;
}
.bg-pattern-diagonal:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    opacity: 0.08;
    background-size: 10px 10px;
    background-image: repeating-linear-gradient(
            45deg,
            #1c1d24 0,
            #1c1d24 1px,
            transparent 0,
            transparent 50%
    );
}
.bg-pattern-polka {
  position: relative;
  overflow: hidden;
}
.bg-pattern-polka:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  opacity: 0.6;
  background-image: radial-gradient(#1c1d24 0.5px, transparent 0.5px), radial-gradient(#1c1d24 0.5px, transparent 0.5px);
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
}
html,
body {
  width: 100%;
  height: 100%;
}
body {
  overflow-x: hidden;
  position: relative;
  background: white;
  color: #787a7c;
}
main {
  position: relative;
  min-height: 100%;
}
.container,
.container-fluid {
  position: relative;
  padding: 0 30px;
}
.theme-light,
body,
[class*="bg-white"],
[class*="bg-black"] [class*="bg-white"],
[class*="bg-dark"] [class*="bg-white"],
[class*="bg-gradient"] [class*="bg-white"] {
  color: #787a7c;
}
.theme-light h1,
body h1,
[class*="bg-white"] h1,
[class*="bg-black"] [class*="bg-white"] h1,
[class*="bg-dark"] [class*="bg-white"] h1,
[class*="bg-gradient"] [class*="bg-white"] h1,
.theme-light h2,
body h2,
[class*="bg-white"] h2,
[class*="bg-black"] [class*="bg-white"] h2,
[class*="bg-dark"] [class*="bg-white"] h2,
[class*="bg-gradient"] [class*="bg-white"] h2,
.theme-light h3,
body h3,
[class*="bg-white"] h3,
[class*="bg-black"] [class*="bg-white"] h3,
[class*="bg-dark"] [class*="bg-white"] h3,
[class*="bg-gradient"] [class*="bg-white"] h3,
.theme-light h4,
body h4,
[class*="bg-white"] h4,
[class*="bg-black"] [class*="bg-white"] h4,
[class*="bg-dark"] [class*="bg-white"] h4,
[class*="bg-gradient"] [class*="bg-white"] h4,
.theme-light h5,
body h5,
[class*="bg-white"] h5,
[class*="bg-black"] [class*="bg-white"] h5,
[class*="bg-dark"] [class*="bg-white"] h5,
[class*="bg-gradient"] [class*="bg-white"] h5,
.theme-light h6,
body h6,
[class*="bg-white"] h6,
[class*="bg-black"] [class*="bg-white"] h6,
[class*="bg-dark"] [class*="bg-white"] h6,
[class*="bg-gradient"] [class*="bg-white"] h6,
.theme-light i,
body i,
[class*="bg-white"] i,
[class*="bg-black"] [class*="bg-white"] i,
[class*="bg-dark"] [class*="bg-white"] i,
[class*="bg-gradient"] [class*="bg-white"] i,
.theme-light .h1,
body .h1,
[class*="bg-white"] .h1,
.theme-light .h2,
body .h2,
[class*="bg-white"] .h2,
.theme-light .h3,
body .h3,
[class*="bg-white"] .h3,
.theme-light .h4,
body .h4,
[class*="bg-white"] .h4,
.theme-light .h5,
body .h5,
[class*="bg-white"] .h5,
.theme-light .h6,
body .h6,
[class*="bg-white"] .h6 {
  color: #1c1d24;
}
.theme-light a:not([class^="button"]):not([class^="link-hover"]),
body a:not([class^="button"]):not([class^="link-hover"]),
[class*="bg-white"] a:not([class^="button"]):not([class^="link-hover"]) {
  color: #1c1d24;
}
.theme-light a:not([class^="button"]):not([class^="link-hover"]):hover,
body a:not([class^="button"]):not([class^="link-hover"]):hover,
[class*="bg-white"] a:not([class^="button"]):not([class^="link-hover"]):hover,
.theme-light a:not([class^="button"]):not([class^="link-hover"]):focus,
body a:not([class^="button"]):not([class^="link-hover"]):focus,
[class*="bg-white"] a:not([class^="button"]):not([class^="link-hover"]):focus {
  color: rgba(28, 29, 36, 0.8);
}
.theme-dark,
[class*="bg-white"] [class*="bg-black"],
[class*="bg-white"] [class*="bg-dark"],
[class*="bg-white"] [class*="bg-gradient"],
[class*="bg-black"],
[class*="bg-dark"],
[class*="bg-gradient"],
.interactive-box .interactive-overlay {
  color: rgba(255, 255, 255, 0.8);
}
.theme-dark h1,
[class*="bg-white"] [class*="bg-black"] h1,
[class*="bg-white"] [class*="bg-dark"] h1,
[class*="bg-white"] [class*="bg-gradient"] h1,
[class*="bg-black"] h1,
[class*="bg-dark"] h1,
[class*="bg-gradient"] h1,
.interactive-box .interactive-overlay h1,
.theme-dark h2,
[class*="bg-white"] [class*="bg-black"] h2,
[class*="bg-white"] [class*="bg-dark"] h2,
[class*="bg-white"] [class*="bg-gradient"] h2,
[class*="bg-black"] h2,
[class*="bg-dark"] h2,
[class*="bg-gradient"] h2,
.interactive-box .interactive-overlay h2,
.theme-dark h3,
[class*="bg-white"] [class*="bg-black"] h3,
[class*="bg-white"] [class*="bg-dark"] h3,
[class*="bg-white"] [class*="bg-gradient"] h3,
[class*="bg-black"] h3,
[class*="bg-dark"] h3,
[class*="bg-gradient"] h3,
.interactive-box .interactive-overlay h3,
.theme-dark h4,
[class*="bg-white"] [class*="bg-black"] h4,
[class*="bg-white"] [class*="bg-dark"] h4,
[class*="bg-white"] [class*="bg-gradient"] h4,
[class*="bg-black"] h4,
[class*="bg-dark"] h4,
[class*="bg-gradient"] h4,
.interactive-box .interactive-overlay h4,
.theme-dark h5,
[class*="bg-white"] [class*="bg-black"] h5,
[class*="bg-white"] [class*="bg-dark"] h5,
[class*="bg-white"] [class*="bg-gradient"] h5,
[class*="bg-black"] h5,
[class*="bg-dark"] h5,
[class*="bg-gradient"] h5,
.interactive-box .interactive-overlay h5,
.theme-dark h6,
[class*="bg-white"] [class*="bg-black"] h6,
[class*="bg-white"] [class*="bg-dark"] h6,
[class*="bg-white"] [class*="bg-gradient"] h6,
[class*="bg-black"] h6,
[class*="bg-dark"] h6,
[class*="bg-gradient"] h6,
.interactive-box .interactive-overlay h6,
.theme-dark i,
[class*="bg-white"] [class*="bg-black"] i,
[class*="bg-white"] [class*="bg-dark"] i,
[class*="bg-white"] [class*="bg-gradient"] i,
[class*="bg-black"] i,
[class*="bg-dark"] i,
[class*="bg-gradient"] i,
.interactive-box .interactive-overlay i,
.theme-dark .h1,
[class*="bg-black"] .h1,
[class*="bg-dark"] .h1,
[class*="bg-gradient"] .h1,
.interactive-box .interactive-overlay .h1,
.theme-dark .h2,
[class*="bg-black"] .h2,
[class*="bg-dark"] .h2,
[class*="bg-gradient"] .h2,
.interactive-box .interactive-overlay .h2,
.theme-dark .h3,
[class*="bg-black"] .h3,
[class*="bg-dark"] .h3,
[class*="bg-gradient"] .h3,
.interactive-box .interactive-overlay .h3,
.theme-dark .h4,
[class*="bg-black"] .h4,
[class*="bg-dark"] .h4,
[class*="bg-gradient"] .h4,
.interactive-box .interactive-overlay .h4,
.theme-dark .h5,
[class*="bg-black"] .h5,
[class*="bg-dark"] .h5,
[class*="bg-gradient"] .h5,
.interactive-box .interactive-overlay .h5,
.theme-dark .h6,
[class*="bg-black"] .h6,
[class*="bg-dark"] .h6,
[class*="bg-gradient"] .h6,
.interactive-box .interactive-overlay .h6 {
  color: white;
}
.theme-dark a:not([class^="button"]):not([class^="link-hover"]),
[class*="bg-black"] a:not([class^="button"]):not([class^="link-hover"]),
[class*="bg-dark"] a:not([class^="button"]):not([class^="link-hover"]),
[class*="bg-gradient"] a:not([class^="button"]):not([class^="link-hover"]),
.interactive-box .interactive-overlay a:not([class^="button"]):not([class^="link-hover"]) {
  color: white;
}
.theme-dark a:not([class^="button"]):not([class^="link-hover"]) i,
[class*="bg-black"] a:not([class^="button"]):not([class^="link-hover"]) i,
[class*="bg-dark"] a:not([class^="button"]):not([class^="link-hover"]) i,
[class*="bg-gradient"] a:not([class^="button"]):not([class^="link-hover"]) i,
.interactive-box .interactive-overlay a:not([class^="button"]):not([class^="link-hover"]) i {
  color: white;
}
.theme-dark a:not([class^="button"]):not([class^="link-hover"]):hover,
[class*="bg-black"] a:not([class^="button"]):not([class^="link-hover"]):hover,
[class*="bg-dark"] a:not([class^="button"]):not([class^="link-hover"]):hover,
[class*="bg-gradient"] a:not([class^="button"]):not([class^="link-hover"]):hover,
.interactive-box .interactive-overlay a:not([class^="button"]):not([class^="link-hover"]):hover,
.theme-dark a:not([class^="button"]):not([class^="link-hover"]):focus,
[class*="bg-black"] a:not([class^="button"]):not([class^="link-hover"]):focus,
[class*="bg-dark"] a:not([class^="button"]):not([class^="link-hover"]):focus,
[class*="bg-gradient"] a:not([class^="button"]):not([class^="link-hover"]):focus,
.interactive-box .interactive-overlay a:not([class^="button"]):not([class^="link-hover"]):focus {
  color: rgba(255, 255, 255, 0.8);
}
.theme-dark a:not([class^="button"]):not([class^="link-hover"]):hover i,
[class*="bg-black"] a:not([class^="button"]):not([class^="link-hover"]):hover i,
[class*="bg-dark"] a:not([class^="button"]):not([class^="link-hover"]):hover i,
[class*="bg-gradient"] a:not([class^="button"]):not([class^="link-hover"]):hover i,
.interactive-box .interactive-overlay a:not([class^="button"]):not([class^="link-hover"]):hover i,
.theme-dark a:not([class^="button"]):not([class^="link-hover"]):focus i,
[class*="bg-black"] a:not([class^="button"]):not([class^="link-hover"]):focus i,
[class*="bg-dark"] a:not([class^="button"]):not([class^="link-hover"]):focus i,
[class*="bg-gradient"] a:not([class^="button"]):not([class^="link-hover"]):focus i,
.interactive-box .interactive-overlay a:not([class^="button"]):not([class^="link-hover"]):focus i {
  color: rgba(255, 255, 255, 0.8);
}
:root {
  --bs-dark-rgb: 28, 29, 36;
  --bs-border-color: rgba(28, 29, 36, 0.1);
}
.section-fullscreen,
[class*="section-spacing"] {
  position: relative;
}
.section-fullscreen {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.section-spacing-xl {
  padding: 140px 0;
}
.section-spacing-lg {
  padding: 120px 0;
}
.section-spacing {
  padding: 80px 0;
}
.section-spacing-sm {
  padding: 80px 0;
}
.section-spacing-xs {
  padding: 65px 0;
}
@media (max-width: 991.98px) {
  .section-spacing-xl {
    padding: 120px 0;
  }
  .section-spacing-lg {
    padding: 100px 0;
  }
  .section-spacing {
    padding: 80px 0;
  }
  .section-spacing-sm {
    padding: 60px 0;
  }
  .section-spacing-xs {
    padding: 50px 0;
  }
}
.custom-row {
  margin: -30px;
}
.custom-row .custom-col {
  padding: 30px;
}
.custom-row-lg {
  margin: -35px;
}
.custom-row-lg .custom-col {
  padding: 35px;
}
@media (max-width: 1199.98px) {
  .custom-row,
  .custom-row-lg {
    margin: -25px;
  }
  .custom-row .custom-col,
  .custom-row-lg .custom-col {
    padding: 25px;
  }
}
@media (max-width: 991.98px) {
  .custom-row,
  .custom-row-lg {
    margin: -20px;
  }
  .custom-row .custom-col,
  .custom-row-lg .custom-col {
    padding: 20px;
  }
}
@media (max-width: 767.98px) {
  .custom-row,
  .custom-row-lg {
    margin: -15px;
  }
  .custom-row .custom-col,
  .custom-row-lg .custom-col {
    padding: 15px;
  }
}
hr.hr-diagonal {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 8px;
  border: 0;
  margin: 0;
}
hr.hr-diagonal:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  opacity: 0.5;
  background-size: 5px 5px;
  background-image: repeating-linear-gradient(45deg, #1c1d24 0, #1c1d24 1px, transparent 0, transparent 50%);
}
[class*="bg-black"] hr.hr-diagonal:before,
[class*="bg-dark"] hr.hr-diagonal:before,
[class*="bg-gradient"] hr.hr-diagonal:before {
  opacity: 0.8;
  background-image: repeating-linear-gradient(45deg, #fff 0, #fff 1px, transparent 0, transparent 50%);
}
input,
textarea {
  background: #f1f2fa;
  width: 100%;
  border: 0;
  margin-bottom: 1rem !important;
  padding: 15px 20px;
  border-radius: 0;
  box-shadow: none;
  font: 400 16px "Open Sans", sans-serif;
  -webkit-transition: ease-out 0.16s;
  transition: ease-out 0.16s;
}
input:focus,
input:active,
textarea:focus,
textarea:active {
  outline: 0;
  box-shadow: none;
}
textarea {
  height: 160px;
}
input,
textarea {
  color: #787a7c;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #aaabac;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #aaabac;
}
input:-ms-placeholder,
textarea:-ms-placeholder {
  color: #aaabac;
}
input::-ms-placeholder,
textarea::-ms-placeholder {
  color: #aaabac;
}
input::placeholder,
textarea::placeholder {
  color: #aaabac;
}
label {
  font-size: 15px;
}
.form-border-radius input,
.form-border-radius textarea {
  border-radius: 0.5em;
}
.form-style-2 input,
.form-style-2 textarea {
  background: transparent;
  margin-bottom: 24px;
  padding: 0 0 14px;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom: 1px solid rgba(28, 29, 36, 0.2);
  border-left-width: 0;
}
.form-style-2 input:focus,
.form-style-2 input:active,
.form-style-2 textarea:focus,
.form-style-2 textarea:active {
  border-bottom-color: #1c1d24;
}
.bg-gray input,
.bg-gray textarea,
.bg-iceblue input,
.bg-iceblue textarea {
  background: white;
}
[class*="bg-black"] input,
[class*="bg-black"] textarea,
[class*="bg-dark"] input,
[class*="bg-dark"] textarea,
[class*="bg-gradient"] input,
[class*="bg-gradient"] textarea {
  color: rgba(255, 255, 255, 0.8);
}
[class*="bg-black"] input::-webkit-input-placeholder,
[class*="bg-black"] textarea::-webkit-input-placeholder,
[class*="bg-dark"] input::-webkit-input-placeholder,
[class*="bg-dark"] textarea::-webkit-input-placeholder,
[class*="bg-gradient"] input::-webkit-input-placeholder,
[class*="bg-gradient"] textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
[class*="bg-black"] input::-moz-placeholder,
[class*="bg-black"] textarea::-moz-placeholder,
[class*="bg-dark"] input::-moz-placeholder,
[class*="bg-dark"] textarea::-moz-placeholder,
[class*="bg-gradient"] input::-moz-placeholder,
[class*="bg-gradient"] textarea::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
[class*="bg-black"] input:-ms-placeholder,
[class*="bg-black"] textarea:-ms-placeholder,
[class*="bg-dark"] input:-ms-placeholder,
[class*="bg-dark"] textarea:-ms-placeholder,
[class*="bg-gradient"] input:-ms-placeholder,
[class*="bg-gradient"] textarea:-ms-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
[class*="bg-black"] input::-ms-placeholder,
[class*="bg-black"] textarea::-ms-placeholder,
[class*="bg-dark"] input::-ms-placeholder,
[class*="bg-dark"] textarea::-ms-placeholder,
[class*="bg-gradient"] input::-ms-placeholder,
[class*="bg-gradient"] textarea::-ms-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
[class*="bg-black"] input::placeholder,
[class*="bg-black"] textarea::placeholder,
[class*="bg-dark"] input::placeholder,
[class*="bg-dark"] textarea::placeholder,
[class*="bg-gradient"] input::placeholder,
[class*="bg-gradient"] textarea::placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.submit-result span {
  display: none;
  -webkit-transition: ease-out 0.2s;
  transition: ease-out 0.2s;
}
.submit-result span.show-result {
  margin-top: 24px;
  display: block;
}
.submit-result span#success {
  color: #32cd32;
}
.submit-result span#error {
  color: #e32227;
}
.submit-result span .error-color {
  background: rgba(255, 0, 0, 0.1);
}
img {
  width: auto;
  max-width: 100%;
  height: auto;
}
.img-mask-1 {
  border-top-left-radius: 4em;
  border-bottom-right-radius: 4em;
}
.img-mask-2 {
  border-top-right-radius: 4em;
  border-bottom-left-radius: 4em;
}
.img-avatar-200 {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.img-avatar-180 {
  width: 180px;
  height: 180px;
  border-radius: 50%;
}
.img-avatar-160 {
  width: 160px;
  height: 160px;
  border-radius: 50%;
}
.img-avatar-80 {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.img-avatar-70 {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.img-avatar-60 {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.avatar-group {
  display: block;
  padding: 0;
}
.avatar-group li {
  list-style-type: none;
  display: inline-block;
  margin-left: -12px;
  padding: 0;
}
.avatar-group li:first-child {
  margin-left: 0;
}
.avatar-group li img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  -webkit-box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.13);
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.13);
  border: 3px solid white;
}
.img-overlap {
  z-index: 1;
  position: relative;
}
.img-overlap .img-1 {
  padding-top: 60px;
  max-width: 55%;
}
.img-overlap .img-2 {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  max-width: 60%;
}
.img-overlap img {
  -webkit-box-shadow: 0 8px 34px 0 rgba(22, 24, 26, 0.1);
  box-shadow: 0 8px 34px 0 rgba(22, 24, 26, 0.1);
}
.img-link-box {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.img-link-box a {
  display: block;
}
.img-link-box img {
  -webkit-transform: scale(1);
  transform: scale(1);
  width: 100%;
  -webkit-transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.img-link-box:hover img {
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
}
ul {
  margin: 0;
}
ul li a {
  -webkit-transition: linear 0.1s;
  transition: linear 0.1s;
}
ul[class*=" list-"],
ul[class^="list-"] {
  padding: 0;
}
ul.list-unstyled li {
  list-style-type: none;
  margin-bottom: 6px;
}
ul.list-unstyled li:last-child {
  margin-bottom: 0;
}
ul.list-dash li {
  list-style-type: none;
  margin-bottom: 6px;
}
ul.list-dash li:before {
  content: "-";
  padding-right: 7px;
}
ul.list-dash li:last-child {
  margin-bottom: 0;
}
ul.list-border-dashed li {
  list-style-type: none;
  padding: 12px 0;
  border-bottom: 1px dashed rgba(28, 29, 36, 0.2);
}
ul.list-border-dashed li:last-child {
  border-bottom: 0;
}
ul[class*=" list-inline"] li,
ul[class^="list-inline"] li {
  display: inline-block;
}
ul.list-inline-dash li {
  padding-right: 5px;
}
ul.list-inline-dash li:before {
  content: "-";
  padding-right: 8px;
}
ul.list-inline-dash li:first-child:before {
  display: none;
}
ul.list-inline-dash li:last-child {
  padding-right: 0;
}
ul.list-inline li {
  padding-right: 4px;
}
ul.list-inline li:last-child {
  padding-right: 0;
}
ul.list-inline-lg li {
  padding-right: 8px;
}
ul.list-inline-lg li:last-child {
  padding-right: 0;
}
ul.list-inline-xl li {
  padding-right: 13px;
}
ul.list-inline-xl li:last-child {
  padding-right: 0;
}
ul.list-inline-2xl li {
  padding-right: 17px;
}
ul.list-inline-2xl li:last-child {
  padding-right: 0;
}
ul.list-inline-dot li {
  position: relative;
  padding: 0 4px 0 14px;
}
ul.list-inline-dot li:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.8);
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
ul.list-inline-dot li:first-child {
  padding-left: 0;
}
ul.list-inline-dot li:first-child:before {
  display: none;
}
[class*="bg-black"] ul.list-border-dashed li,
[class*="bg-dark"] ul.list-border-dashed li,
[class*="bg-gradient"] ul.list-border-dashed li {
  border-color: rgba(255, 255, 255, 0.3);
}
[class*="bg-black"] ul.list-inline-dot li:before,
[class*="bg-dark"] ul.list-inline-dot li:before,
[class*="bg-gradient"] ul.list-inline-dot li:before {
  background: rgba(255, 255, 255, 0.8);
}
body {
  color: #787a7c;
  font-family: "Nunito", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0;
}
@media (max-width: 991.98px) {
  body {
    font-size: 0.938rem;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: black;
  font-family: "Outfit",  sans-serif;
  font-weight: 500;
}
@media (max-width: 991.98px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    margin-bottom: 6px;
  }
}
@media (min-width: 1200px) {
  .display-lg {
    font-size: 6rem;
  }
  .display-xl {
    font-size: 7rem;
  }
}
p {
  margin: 0;
  line-height: 1.74;
}
.uppercase {
  letter-spacing: 1px;
  text-transform: uppercase;
}
.font-small {
  font-size: 0.9em;
  line-height: 1.7;
}
@media (max-width: 991.98px) {
  .font-small {
    font-size: 0.9em;
    line-height: 1.7;
  }
}
.font-large {
  font-size: 1.063em;
  line-height: 1.6;
}
@media (max-width: 991.98px) {
  .font-large {
    font-size: 1.063em;
  }
}
.sm-heading {
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  font-size: 0.9em;
  text-transform: uppercase;
  letter-spacing: 1px;
}
i {
  display: inline-block;
}
i.icon-4xl {
  font-size: 2.4em;
}
i.icon-3xl {
  font-size: 2.13em;
}
i.icon-2xl {
  font-size: 1.86em;
}
i.icon-xl {
  font-size: 1.6em;
}
i.icon-lg {
  font-size: 1.33em;
}
i.icon-sm {
  font-size: 0.9em;
}
.icon-4xl i {
  font-size: 2.4em;
}
.icon-3xl i {
  font-size: 2.13em;
}
.icon-2xl i {
  font-size: 1.86em;
}
.icon-xl i {
  font-size: 1.6em;
}
.icon-lg i {
  font-size: 1.33em;
}
.icon-sm i {
  font-size: 0.9em;
}
.accordion {
  list-style-type: none;
  padding: 0;
}
.accordion li {
  border: 1px dashed rgba(28, 29, 36, 0.2);
  margin-bottom: 12px;
  padding: 20px 30px;
  -webkit-transition: linear 0.1s;
  transition: linear 0.1s;
}
.accordion li:last-child {
  margin-bottom: 0;
}
.accordion li .accordion-title {
  z-index: 1;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  -webkit-transition: linear 0.1s;
  transition: linear 0.1s;
}
.accordion li .accordion-title .accordion-toggle {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.accordion li .accordion-title .accordion-toggle span {
  position: relative;
  display: inline-block;
  width: 11px;
  height: 11px;
}
.accordion li .accordion-title .accordion-toggle span:before,
.accordion li .accordion-title .accordion-toggle span:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: black;
  width: 11px;
  height: 1px;
}
.accordion li .accordion-title .accordion-toggle span:after {
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
  transform: translate(-50%, -50%) rotate(90deg);
  -webkit-transition: linear 0.1s;
  transition: linear 0.1s;
}
.accordion li .accordion-title * {
  margin: 0;
  -webkit-transition: linear 0.1s;
  transition: linear 0.1s;
}
.accordion li .accordion-content {
  overflow: hidden;
  max-height: 0;
  -webkit-transition: ease-out 0.2s;
  transition: ease-out 0.2s;
}
.accordion li .accordion-content:before {
  content: "";
  display: block;
  width: 100%;
  height: 12px;
}
.accordion li .accordion-content ul li,
.accordion li .accordion-content ol li {
  border: 0;
  padding: 0;
}
.accordion li .accordion-content ul li:last-child,
.accordion li .accordion-content ol li:last-child {
  border: 0;
}
.accordion li .accordion-content ul {
  list-style-type: disc;
}
.accordion li .accordion-content ul ul {
  list-style-type: circle;
}
.accordion li .accordion-content ol.list-ordered li {
  margin-bottom: 8px;
  padding-left: 34px;
}
.accordion li .accordion-content ol.list-ordered li:last-child {
  margin: 0;
}
.accordion li .accordion-content .nav {
  list-style-type: none;
}
.accordion li.active {
  border-color: #1c1d24;
}
.accordion li.active .accordion-title .accordion-toggle span:after {
  opacity: 0;
  -webkit-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0);
}
.accordion.style-2 li {
  margin-bottom: 0;
  padding: 20px 0;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 1px dashed rgba(28, 29, 36, 0.2);
}
.accordion.style-2 li:last-child {
  border-bottom: 0;
}
[class*="bg-black"] .accordion li,
[class*="bg-dark"] .accordion li {
  border-color: rgba(255, 255, 255, 0.2);
}
[class*="bg-black"] .accordion li .accordion-title .accordion-toggle span:before,
[class*="bg-black"] .accordion li .accordion-title .accordion-toggle span:after,
[class*="bg-dark"] .accordion li .accordion-title .accordion-toggle span:before,
[class*="bg-dark"] .accordion li .accordion-title .accordion-toggle span:after {
  background: white;
}
[class*="bg-black"] .accordion li.active,
[class*="bg-dark"] .accordion li.active {
  border-color: white;
}
.box-shadow {
  -webkit-box-shadow: 0 8px 34px 0 rgba(22, 24, 26, 0.1);
  box-shadow: 0 8px 34px 0 rgba(22, 24, 26, 0.1);
  -webkit-transition: ease-out 0.16s;
  transition: ease-out 0.16s;
}
.box-shadow:hover {
  -webkit-box-shadow: 0 8px 38px 0 rgba(22, 24, 26, 0.13);
  box-shadow: 0 8px 38px 0 rgba(22, 24, 26, 0.13);
}
img.box-shadow {
  -webkit-box-shadow: 0 8px 34px 0 rgba(22, 24, 26, 0.12);
  box-shadow: 0 8px 34px 0 rgba(22, 24, 26, 0.12);
}
img.box-shadow:hover {
  -webkit-box-shadow: 0 8px 38px 0 rgba(22, 24, 26, 0.15);
  box-shadow: 0 8px 38px 0 rgba(22, 24, 26, 0.15);
}
.hover-float {
  -webkit-transition: ease-out 0.16s;
  transition: ease-out 0.16s;
}
.hover-float:hover {
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
}
.border-radius {
  border-radius: 0.5em;
  overflow: hidden;
}
.border-radius-1 {
  border-radius: 1em;
  overflow: hidden;
}
.border-radius-2 {
  border-radius: 2em;
  overflow: hidden;
}
.icon-box-square,
.icon-box-circle {
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}
.icon-box-square-lg {
  width: 70px;
  height: 70px;
  line-height: 70px;
}
.icon-box-square-sm {
  width: 50px;
  height: 50px;
  line-height: 50px;
}
.icon-box-circle {
  border-radius: 50%;
}
.icon-box-circle.icon-box-circle-lg {
  width: 70px;
  height: 70px;
  line-height: 70px;
}
.icon-box-circle.icon-box-circle-sm {
  width: 50px;
  height: 50px;
  line-height: 50px;
}
.interactive-box {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.interactive-box img {
  -webkit-transform: scale(1);
  transform: scale(1);
  width: 100%;
  -webkit-transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.interactive-box .interactive-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to top, rgb(0 0 0), rgb(0 0 0 / 56%), #00000000, transparent);
    -webkit-transition: linear 0.1s;
    transition: linear 0.1s;
}
.interactive-box .interactive-overlay .interactive-inner {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.interactive-box .interactive-overlay .interactive-hover {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
  width: 100%;
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.interactive-box:hover .interactive-overlay .interactive-inner {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}
.interactive-box:hover .interactive-overlay .interactive-hover {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.interactive-box:hover img {
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
}
.float-card img {
  z-index: -1;
}
.float-card .float-card-text {
  position: relative;
  margin-top: -2em;
  margin-right: 1em;
  margin-left: 1em;
  padding: 30px;
}
.hover-border {
  border: 1px solid transparent;
  -webkit-transition: linear 0.1s;
  transition: linear 0.1s;
}
.hover-border:hover,
.hover-border.hover-border-active {
  border-color: #1c1d24;
}
.hover-border-wrapper:hover .hover-border {
  border-color: transparent;
}
.hover-border-wrapper:hover .hover-border:hover {
  border-color: #1c1d24;
}
[class*="bg-black"] .hover-border:hover,
[class*="bg-black"] .hover-border.hover-border-active,
[class*="bg-dark"] .hover-border:hover,
[class*="bg-dark"] .hover-border.hover-border-active,
[class*="bg-gradient"] .hover-border:hover,
[class*="bg-gradient"] .hover-border.hover-border-active {
  border-color: white;
}
[class*="bg-black"] .hover-border-wrapper:hover .hover-border,
[class*="bg-dark"] .hover-border-wrapper:hover .hover-border,
[class*="bg-gradient"] .hover-border-wrapper:hover .hover-border {
  border-color: transparent;
}
[class*="bg-black"] .hover-border-wrapper:hover .hover-border:hover,
[class*="bg-dark"] .hover-border-wrapper:hover .hover-border:hover,
[class*="bg-gradient"] .hover-border-wrapper:hover .hover-border:hover {
  border-color: white;
}
button {
  background: transparent;
  box-shadow: none;
  border: 0;
  padding: 0;
  cursor: pointer;
}
a,
button {
  text-decoration: none;
  -webkit-transition: linear 0.1s;
  transition: linear 0.1s;
}
a:focus,
a:active,
button:focus,
button:active {
  box-shadow: none;
  outline: 0;
}
.button {
  position: relative;
  display: inline-block;
  background: #ecedf5;
  color: #1c1d24;
  padding: 13px 30px;
  font-family: "Nunito", sans-serif;
}
.button-xs {
  padding: 7px 15px;
}
.button-sm {
  padding: 11px 23px;
}
.button-lg {
  padding: 15px 34px;
}
.button-fullwidth {
  width: 100%;
  text-align: center;
}
.button-radius {
  border-radius: 0.5em;
}
.button-rounded {
  border-radius: 30px;
}
.button-hover-slide {
  overflow: hidden;
  vertical-align: middle;
}
.button-hover-slide span {
  display: inline-block;
  position: relative;
  color: transparent;
}
.button-hover-slide span:before,
.button-hover-slide span:after {
  content: attr(data-text);
  position: absolute;
  left: 0;
  color: white;
  -webkit-transition: ease-out 0.16s;
  transition: ease-out 0.16s;
}
.button-hover-slide span:before {
  top: 0;
}
.button-hover-slide span:after {
  visibility: hidden;
  opacity: 0;
  top: 50%;
}
.button-hover-slide:hover:before {
  opacity: 0.4;
  -webkit-transform: translateY(-50%) scale(2.7);
  transform: translateY(-50%) scale(2.7);
}
.button-hover-slide:hover span:before {
  visibility: hidden;
  opacity: 0;
  top: -50%;
}
.button-hover-slide:hover span:after {
  visibility: visible;
  opacity: 1;
  top: 0;
}
.button-hover-float:hover {
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
}
.button-dark {
  background: #1c1d24;
  color: white;
}
.button-dark:hover {
  background: rgba(28, 29, 36, 0.85);
}
.button-white {
  background: white;
  color: #1c1d24;
}
.button-white.button-hover-slide span:before,
.button-white.button-hover-slide span:after {
  color: #1c1d24;
}
.button-white-2 {
  background: rgba(255, 255, 255, 0.15);
  color: white;
}
.button-white-2:hover {
  background: rgba(255, 255, 255, 0.2);
}
.button-outline,
.button-outline-dashed {
  background: transparent;
  color: #1c1d24;
}
.button-outline.button-hover-slide span:before,
.button-outline.button-hover-slide span:after,
.button-outline-dashed.button-hover-slide span:before,
.button-outline-dashed.button-hover-slide span:after {
  color: #1c1d24;
}
.button-outline {
  border: 1px solid #1c1d24;
}
.button-outline:hover {
  border-color: rgba(28, 29, 36, 0.6);
}
.button-outline-dashed {
  border: 1px dashed #1c1d24;
}
.button-outline-dashed:hover {
  border-color: rgba(28, 29, 36, 0.6);
}
[class*="bg-black"] .button-outline,
[class*="bg-black"] .button-outline-dashed,
[class*="bg-dark"] .button-outline,
[class*="bg-dark"] .button-outline-dashed,
[class*="bg-gradient"] .button-outline,
[class*="bg-gradient"] .button-outline-dashed,
.header-dark:not(.transparent-dark) .button-outline,
.header-dark:not(.transparent-dark) .button-outline-dashed,
.header.transparent-light .button-outline,
.header.transparent-light .button-outline-dashed {
  background: transparent;
  border-color: white;
  color: white;
}
[class*="bg-black"] .button-outline:hover,
[class*="bg-black"] .button-outline-dashed:hover,
[class*="bg-dark"] .button-outline:hover,
[class*="bg-dark"] .button-outline-dashed:hover,
[class*="bg-gradient"] .button-outline:hover,
[class*="bg-gradient"] .button-outline-dashed:hover,
.header-dark:not(.transparent-dark) .button-outline:hover,
.header-dark:not(.transparent-dark) .button-outline-dashed:hover,
.header.transparent-light .button-outline:hover,
.header.transparent-light .button-outline-dashed:hover {
  background: transparent;
}
[class*="bg-black"] .button-outline.button-hover-slide span:before,
[class*="bg-black"] .button-outline.button-hover-slide span:after,
[class*="bg-black"] .button-outline-dashed.button-hover-slide span:before,
[class*="bg-black"] .button-outline-dashed.button-hover-slide span:after,
[class*="bg-dark"] .button-outline.button-hover-slide span:before,
[class*="bg-dark"] .button-outline.button-hover-slide span:after,
[class*="bg-dark"] .button-outline-dashed.button-hover-slide span:before,
[class*="bg-dark"] .button-outline-dashed.button-hover-slide span:after,
[class*="bg-gradient"] .button-outline.button-hover-slide span:before,
[class*="bg-gradient"] .button-outline.button-hover-slide span:after,
[class*="bg-gradient"] .button-outline-dashed.button-hover-slide span:before,
[class*="bg-gradient"] .button-outline-dashed.button-hover-slide span:after,
.header-dark:not(.transparent-dark) .button-outline.button-hover-slide span:before,
.header-dark:not(.transparent-dark) .button-outline.button-hover-slide span:after,
.header-dark:not(.transparent-dark) .button-outline-dashed.button-hover-slide span:before,
.header-dark:not(.transparent-dark) .button-outline-dashed.button-hover-slide span:after,
.header.transparent-light .button-outline.button-hover-slide span:before,
.header.transparent-light .button-outline.button-hover-slide span:after,
.header.transparent-light .button-outline-dashed.button-hover-slide span:before,
.header.transparent-light .button-outline-dashed.button-hover-slide span:after {
  color: white;
}
[class*="bg-black"] .button-outline:hover,
[class*="bg-dark"] .button-outline:hover,
[class*="bg-gradient"] .button-outline:hover,
.header-dark:not(.transparent-dark) .button-outline:hover,
.header.transparent-light .button-outline:hover {
  border-color: rgba(255, 255, 255, 0.7);
}
[class*="bg-black"] .button-outline-dashed:hover,
[class*="bg-dark"] .button-outline-dashed:hover,
[class*="bg-gradient"] .button-outline-dashed:hover,
.header-dark:not(.transparent-dark) .button-outline-dashed:hover,
.header.transparent-light .button-outline-dashed:hover {
  border-color: rgba(255, 255, 255, 0.6);
}
[class*="button-fancy"] {
  position: relative;
  display: inline-block;
  padding-left: 30px;
  height: 50px;
  line-height: 50px;
  font-family: "Nunito", sans-serif;
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
[class*="button-fancy"]:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transform-origin: center left;
  transform-origin: center left;
  width: 50px;
  height: 50px;
  border-radius: 30px;
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
[class*="button-fancy"]:hover {
  padding-left: 22px;
  padding-right: 8px;
}
[class*="button-fancy"]:hover:before {
  -webkit-transform: translateY(-50%) scale(0.25);
  transform: translateY(-50%) scale(0.25);
}
[class*="button-fancy"].button-fancy-right {
  padding-left: 0;
  padding-right: 30px;
}
[class*="button-fancy"].button-fancy-right:before {
  left: auto;
  right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}
[class*="button-fancy"].button-fancy-right:hover {
  padding-left: 8px;
  padding-right: 22px;
}
.button-fancy {
  color: #1c1d24;
}
.button-fancy:before {
  background: rgba(28, 29, 36, 0.05);
}
.button-fancy:hover:before {
  background: #1c1d24;
}
[class*="button-fancy-gradient"]:before {
  opacity: 0.1;
}
[class*="button-fancy-gradient"]:hover:before {
  opacity: 1;
}
.button-fancy-gradient-1 {
  background-image: linear-gradient(45deg, #ff5988, #500c7f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.button-fancy-gradient-1:before {
  background-image: linear-gradient(45deg, #ff5988, #500c7f);
}
.button-fancy-gradient-2 {
  background-image: linear-gradient(45deg, #2fbdff, #0f2f40);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.button-fancy-gradient-2:before {
  background-image: linear-gradient(45deg, #2fbdff, #0f2f40);
}
.button-fancy-gradient-3 {
  background-image: linear-gradient(45deg, #ff2f78, #ff7582);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.button-fancy-gradient-3:before {
  background-image: linear-gradient(45deg, #ff2f78, #ff7582);
}
.button-fancy-gradient-4 {
  background-image: linear-gradient(45deg, #00dbde, #fc00ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.button-fancy-gradient-4:before {
  background-image: linear-gradient(45deg, #00dbde, #fc00ff);
}
.button-fancy-gradient-5 {
  background-image: linear-gradient(45deg, #007990, #6ce5c0);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.button-fancy-gradient-5:before {
  background-image: linear-gradient(45deg, #007990, #6ce5c0);
}
[class*="bg-black"] .button-fancy,
[class*="bg-dark"] .button-fancy,
[class*="bg-gradient"] .button-fancy {
  color: white;
}
[class*="bg-black"] .button-fancy:before,
[class*="bg-dark"] .button-fancy:before,
[class*="bg-gradient"] .button-fancy:before {
  background: rgba(255, 255, 255, 0.15);
}
[class*="bg-black"] .button-fancy:hover:before,
[class*="bg-dark"] .button-fancy:hover:before,
[class*="bg-gradient"] .button-fancy:hover:before {
  background: white;
}
[class*="bg-black"] [class*="button-fancy-gradient"]:hover:before,
[class*="bg-dark"] [class*="button-fancy-gradient"]:hover:before,
[class*="bg-gradient"] [class*="button-fancy-gradient"]:hover:before {
  opacity: 1;
}
.button-circle {
  position: relative;
  background: #ecedf5;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #1c1d24;
  line-height: 50px;
  text-align: center;
}
.button-circle-sm {
  width: 44px;
  height: 44px;
  line-height: 44px;
}
.button-circle-lg {
  width: 56px;
  height: 56px;
  line-height: 56px;
}
.button-circle-dark {
  background: #1c1d24;
  color: white;
}
.button-circle-dark i {
  color: white;
}
.button-circle-dark:hover {
  background: rgba(28, 29, 36, 0.85);
}
.button-circle-white {
  background: white;
  color: #1c1d24;
}
.button-circle-white-2 {
  background: rgba(255, 255, 255, 0.15);
  color: white;
}
.button-circle-white-2 i {
  color: white;
}
.button-circle-white-2:hover {
  background: rgba(255, 255, 255, 0.2);
}
.button-circle-outline,
.button-circle-outline-dashed {
  background: transparent;
  color: #1c1d24;
}
.button-circle-outline i,
.button-circle-outline-dashed i {
  color: #1c1d24;
}
.button-circle-outline {
  border: 1px solid #1c1d24;
}
.button-circle-outline:hover {
  border-color: rgba(28, 29, 36, 0.6);
}
.button-circle-outline-dashed {
  border: 1px dashed #1c1d24;
}
.button-circle-outline-dashed:hover {
  border-color: rgba(28, 29, 36, 0.6);
}
.button-gradient-1 {
  background: unset;
  background-image: linear-gradient(to right, #ff5988, #500c7f, #ff5988);
}
.button-gradient-2 {
  background: unset;
  background-image: linear-gradient(to right, #2fbdff, #0f2f40, #2fbdff);
}
.button-gradient-3 {
  background: unset;
  background-image: linear-gradient(to right, #ff2f78, #ff7582, #ff2f78);
}
.button-gradient-4 {
  background: unset;
  background-image: linear-gradient(to right, #00dbde, #fc00ff, #00dbde);
}
.button-gradient-5 {
  background: unset;
  background-image: linear-gradient(to right, #007990, #6ce5c0, #007990);
}
[class*="button-gradient"] {
  -webkit-background-size: 300% 100%;
  -moz-background-size: 300% 100%;
  background-size: 300% 100%;
  border: 0;
  -webkit-box-shadow: 0 6px 30px 0 rgba(21, 25, 29, 0.13);
  box-shadow: 0 6px 30px 0 rgba(21, 25, 29, 0.13);
  color: white;
  -webkit-transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}
[class*="button-gradient"] i {
  color: white;
}
[class*="button-gradient"]:hover,
[class*="button-gradient"]:focus {
  -webkit-box-shadow: 0 6px 36px 0 rgba(21, 25, 29, 0.19);
  box-shadow: 0 6px 36px 0 rgba(21, 25, 29, 0.19);
  background-position: 100% 0;
  color: white;
}
.button-circle-hover-slide {
  overflow: hidden;
  line-height: 1;
}
.button-circle-hover-slide i {
  position: absolute;
  left: 50%;
  -webkit-transition: ease-out 0.16s;
  transition: ease-out 0.16s;
}
.button-circle-hover-slide i:first-child {
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  opacity: 1;
}
.button-circle-hover-slide i:last-child {
  top: auto;
  bottom: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0;
}
.button-circle-hover-slide:hover i:first-child {
  top: 0;
  -webkit-transform: translateX(-50%) translateY(0);
  transform: translateX(-50%) translateY(0);
  opacity: 0;
}
.button-circle-hover-slide:hover i:last-child {
  top: 50%;
  bottom: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  opacity: 1;
}
[class*="bg-black"] .button-circle-outline,
[class*="bg-black"] .button-circle-outline-dashed,
[class*="bg-dark"] .button-circle-outline,
[class*="bg-dark"] .button-circle-outline-dashed,
[class*="bg-gradient"] .button-circle-outline,
[class*="bg-gradient"] .button-circle-outline-dashed,
.header-dark:not(.transparent-dark) .button-circle-outline,
.header-dark:not(.transparent-dark) .button-circle-outline-dashed,
.header.transparent-light .button-circle-outline,
.header.transparent-light .button-circle-outline-dashed {
  background: transparent;
  border-color: white;
  color: white;
}
[class*="bg-black"] .button-circle-outline i,
[class*="bg-black"] .button-circle-outline-dashed i,
[class*="bg-dark"] .button-circle-outline i,
[class*="bg-dark"] .button-circle-outline-dashed i,
[class*="bg-gradient"] .button-circle-outline i,
[class*="bg-gradient"] .button-circle-outline-dashed i,
.header-dark:not(.transparent-dark) .button-circle-outline i,
.header-dark:not(.transparent-dark) .button-circle-outline-dashed i,
.header.transparent-light .button-circle-outline i,
.header.transparent-light .button-circle-outline-dashed i {
  color: white;
}
[class*="bg-black"] .button-circle-outline:hover,
[class*="bg-black"] .button-circle-outline-dashed:hover,
[class*="bg-dark"] .button-circle-outline:hover,
[class*="bg-dark"] .button-circle-outline-dashed:hover,
[class*="bg-gradient"] .button-circle-outline:hover,
[class*="bg-gradient"] .button-circle-outline-dashed:hover,
.header-dark:not(.transparent-dark) .button-circle-outline:hover,
.header-dark:not(.transparent-dark) .button-circle-outline-dashed:hover,
.header.transparent-light .button-circle-outline:hover,
.header.transparent-light .button-circle-outline-dashed:hover {
  background: transparent;
}
[class*="bg-black"] .button-circle-outline.button-circle-hover-slide,
[class*="bg-black"] .button-circle-outline-dashed.button-circle-hover-slide,
[class*="bg-dark"] .button-circle-outline.button-circle-hover-slide,
[class*="bg-dark"] .button-circle-outline-dashed.button-circle-hover-slide,
[class*="bg-gradient"] .button-circle-outline.button-circle-hover-slide,
[class*="bg-gradient"] .button-circle-outline-dashed.button-circle-hover-slide,
.header-dark:not(.transparent-dark) .button-circle-outline.button-circle-hover-slide,
.header-dark:not(.transparent-dark) .button-circle-outline-dashed.button-circle-hover-slide,
.header.transparent-light .button-circle-outline.button-circle-hover-slide,
.header.transparent-light .button-circle-outline-dashed.button-circle-hover-slide {
  color: white;
}
[class*="bg-black"] .button-circle-outline.button-circle-hover-slide i,
[class*="bg-black"] .button-circle-outline-dashed.button-circle-hover-slide i,
[class*="bg-dark"] .button-circle-outline.button-circle-hover-slide i,
[class*="bg-dark"] .button-circle-outline-dashed.button-circle-hover-slide i,
[class*="bg-gradient"] .button-circle-outline.button-circle-hover-slide i,
[class*="bg-gradient"] .button-circle-outline-dashed.button-circle-hover-slide i,
.header-dark:not(.transparent-dark) .button-circle-outline.button-circle-hover-slide i,
.header-dark:not(.transparent-dark) .button-circle-outline-dashed.button-circle-hover-slide i,
.header.transparent-light .button-circle-outline.button-circle-hover-slide i,
.header.transparent-light .button-circle-outline-dashed.button-circle-hover-slide i {
  color: white;
}
[class*="bg-black"] .button-circle-outline:hover,
[class*="bg-dark"] .button-circle-outline:hover,
[class*="bg-gradient"] .button-circle-outline:hover,
.header-dark:not(.transparent-dark) .button-circle-outline:hover,
.header.transparent-light .button-circle-outline:hover {
  border-color: rgba(255, 255, 255, 0.7);
}
[class*="bg-black"] .button-circle-outline-dashed:hover,
[class*="bg-dark"] .button-circle-outline-dashed:hover,
[class*="bg-gradient"] .button-circle-outline-dashed:hover,
.header-dark:not(.transparent-dark) .button-circle-outline-dashed:hover,
.header.transparent-light .button-circle-outline-dashed:hover {
  border-color: rgba(255, 255, 255, 0.6);
}
.button-shadow {
  -webkit-box-shadow: 0 6px 30px 0 rgba(22, 24, 26, 0.1);
  box-shadow: 0 6px 30px 0 rgba(22, 24, 26, 0.1);
}
.button-shadow:hover,
.button-shadow:focus {
  -webkit-box-shadow: 0 6px 36px 0 rgba(22, 24, 26, 0.13);
  box-shadow: 0 6px 36px 0 rgba(22, 24, 26, 0.13);
}
.circle-text {
  position: relative;
  max-width: 300px;
  max-height: 300px;
  text-transform: uppercase;
  font-weight: bold;
}
.circle-text .circle-svg {
  max-width: 100%;
  height: auto;
}
.circle-text .circle-text-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.rotating {
  animation: rotating 20s linear infinite;
}
@keyframes rotating {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.client-box a img {
  opacity: 0.5;
  width: 100%;
  -webkit-transition: ease-out 0.12s;
  transition: ease-out 0.12s;
}
.client-box a:hover img {
  opacity: 1;
}
.client-box-with-link {
  position: relative;
}
.client-box-with-link a {
  display: block;
}
.client-box-with-link a img {
  opacity: 0.8;
  width: 100%;
  -webkit-transition: ease-out 0.12s;
  transition: ease-out 0.12s;
}
.client-box-with-link a span {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: block;
  width: 100%;
  text-align: center;
  -webkit-transition: ease-out 0.12s;
  transition: ease-out 0.12s;
}
.client-box-with-link a:hover img {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
.client-box-with-link a:hover span {
  opacity: 1;
  visibility: visible;
}
.bg-lines-5 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.bg-lines-5 .line-1,
.bg-lines-5 .line-2,
.bg-lines-5 .line-3,
.bg-lines-5 .line-4,
.bg-lines-5 .line-5 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-right: 1px solid rgba(28, 29, 36, 0.1);
}
.bg-lines-5 .line-1:before,
.bg-lines-5 .line-2:before,
.bg-lines-5 .line-3:before,
.bg-lines-5 .line-4:before,
.bg-lines-5 .line-5:before {
  content: "";
  position: absolute;
  top: -80px;
  right: -1px;
  background: linear-gradient(transparent, rgba(28, 29, 36, 0.6));
  width: 1px;
  height: 80px;
}
.bg-lines-5 .line-1 {
  width: 10%;
}
.bg-lines-5 .line-2 {
  width: 30%;
}
.bg-lines-5 .line-3 {
  width: 50%;
}
.bg-lines-5 .line-4 {
  width: 70%;
}
.bg-lines-5 .line-5 {
  width: 90%;
}
.bg-lines-5.bg-lines-animated .line-1:before {
  -webkit-animation: bgLine 8s linear infinite;
  animation: bgLine 8s linear infinite;
}
.bg-lines-5.bg-lines-animated .line-2:before {
  -webkit-animation: bgLine 9s linear infinite;
  animation: bgLine 9s linear infinite;
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}
.bg-lines-5.bg-lines-animated .line-3:before {
  -webkit-animation: bgLine 7s linear infinite;
  animation: bgLine 7s linear infinite;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}
.bg-lines-5.bg-lines-animated .line-4:before {
  -webkit-animation: bgLine 10s linear infinite;
  animation: bgLine 10s linear infinite;
  -webkit-animation-delay: 8s;
  animation-delay: 8s;
}
.bg-lines-5.bg-lines-animated .line-5:before {
  -webkit-animation: bgLine 8.5s linear infinite;
  animation: bgLine 8.5s linear infinite;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
@media (max-width: 991.98px) {
  .bg-lines-5 .line-1 {
    width: 10%;
  }
  .bg-lines-5 .line-2 {
    width: 50%;
  }
  .bg-lines-5 .line-3 {
    width: 90%;
  }
  .bg-lines-5 .line-4,
  .bg-lines-5 .line-5 {
    display: none;
  }
}
@-webkit-keyframes bgLine {
  0% {
    top: -80px;
  }
  100% {
    top: 100%;
  }
}
@keyframes bgLine {
  0% {
    top: -80px;
  }
  100% {
    top: 100%;
  }
}
.bg-lines-3 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.bg-lines-3 .line-1,
.bg-lines-3 .line-2,
.bg-lines-3 .line-3 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-right: 1px solid rgba(28, 29, 36, 0.1);
}
.bg-lines-3 .line-1:before,
.bg-lines-3 .line-2:before,
.bg-lines-3 .line-3:before {
  content: "";
  position: absolute;
  top: -80px;
  right: -1px;
  background: linear-gradient(transparent, rgba(28, 29, 36, 0.6));
  width: 1px;
  height: 80px;
}
.bg-lines-3 .line-1 {
  width: 10%;
}
.bg-lines-3 .line-2 {
  width: 50%;
}
.bg-lines-3 .line-3 {
  width: 90%;
}
.bg-lines-3.bg-lines-animated .line-1:before {
  -webkit-animation: bgLine 8s linear infinite;
  animation: bgLine 8s linear infinite;
}
.bg-lines-3.bg-lines-animated .line-2:before {
  -webkit-animation: bgLine 9s linear infinite;
  animation: bgLine 9s linear infinite;
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}
.bg-lines-3.bg-lines-animated .line-3:before {
  -webkit-animation: bgLine 7s linear infinite;
  animation: bgLine 7s linear infinite;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}
[class*="bg-black"] .bg-lines-5 *,
[class*="bg-black"] .bg-lines-3 *,
[class*="bg-dark"] .bg-lines-5 *,
[class*="bg-dark"] .bg-lines-3 *,
[class*="bg-gradient"] .bg-lines-5 *,
[class*="bg-gradient"] .bg-lines-3 * {
  border-color: rgba(255, 255, 255, 0.15);
}
[class*="bg-black"] .bg-lines-5 *:before,
[class*="bg-black"] .bg-lines-3 *:before,
[class*="bg-dark"] .bg-lines-5 *:before,
[class*="bg-dark"] .bg-lines-3 *:before,
[class*="bg-gradient"] .bg-lines-5 *:before,
[class*="bg-gradient"] .bg-lines-3 *:before {
  background: linear-gradient(transparent, rgba(255, 255, 255, 0.7));
}
.cursors {
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.cursors .mouse-caption,
.cursors img {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 8px;
}
.cursors .mouse-caption {
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.5);
  padding: 16px 20px;
}
.cursors .mouse-caption * {
  margin: 0;
}
.cursors .mouse-caption h2 {
  padding: 4px;
}
.cursors .mouse-caption.mouse-caption-dark {
  background: rgba(28, 29, 36, 0.7);
  color: white;
}
.cursors .mouse-caption.mouse-caption-dark * {
  color: white;
}
.cursors img {
  min-width: 300px;
  width: 300px;
  -webkit-transition: linear 0.1s;
  transition: linear 0.1s;
}
@media (max-width: 991.98px) {
  .cursors {
    display: none;
  }
}
#particles-js {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.scrolltotop {
  z-index: 996;
  position: fixed;
  right: 20px;
  bottom: 20px;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  background: rgba(255, 255, 255, 0.1);
  width: 46px;
  height: 46px;
  line-height: 46px;
  border-radius: 50%;
  color: white;
  text-align: center;
  mix-blend-mode: difference;
  cursor: pointer;
  backdrop-filter: blur(5px);
  -webkit-transition: ease-out 0.12s;
  transition: ease-out 0.12s;
}
.scrolltotop * {
  color: white;
}
.scrolltotop.show {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}
@media (max-width: 991.98px) {
  .scrolltotop {
    right: 16px;
    bottom: 16px;
  }
}
.lightbox-video {
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  overflow: hidden;
}
.lightbox-video img {
  -webkit-transform: scale(1);
  transform: scale(1);
  width: 100%;
  -webkit-transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.lightbox-video .lightbox-play-btn {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 50%;
  text-align: center;
  color: white;
  font-size: 1.6em;
}
.lightbox-video .lightbox-play-btn i {
  color: white;
}
.lightbox-video .lightbox-play-btn:before,
.lightbox-video .lightbox-play-btn:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  -webkit-transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.lightbox-video .lightbox-play-btn:before {
  z-index: -1;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}
.lightbox-video .lightbox-play-btn:after {
  z-index: -1;
  -webkit-transform: scale(2.5);
  transform: scale(2.5);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}
.lightbox-video .lightbox-play-btn:hover {
  color: white;
}
.lightbox-video .lightbox-play-btn:hover:before {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.lightbox-video .lightbox-play-btn:hover:after {
  -webkit-transform: scale(2.6);
  transform: scale(2.6);
}
.lightbox-video:hover img {
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
}
@media (max-width: 1199.98px) {
  .lightbox-video .lightbox-play-btn {
    width: 80px;
    height: 80px;
    line-height: 80px;
  }
}
@media (max-width: 991.98px) {
  .lightbox-video .lightbox-play-btn {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
}
.link-hover-line {
  z-index: 1;
  position: relative;
  color: black;
}
.link-hover-line:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: -1px;
  left: 0;
  background: black;
  height: 1px;
  -webkit-animation: line-out 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) both;
  animation: line-out 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) both;
}
.link-hover-line:hover {
  color: black;
}
.link-hover-line:hover:before {
  width: 100%;
  -webkit-animation: line-in 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) both;
  animation: line-in 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) both;
}
[class*="bg-black"] .link-hover-line,
[class*="bg-dark"] .link-hover-line,
[class*="bg-gradient"] .link-hover-line {
  color: white;
}
[class*="bg-black"] .link-hover-line:before,
[class*="bg-dark"] .link-hover-line:before,
[class*="bg-gradient"] .link-hover-line:before {
  background: white;
}
[class*="bg-black"] .link-hover-line:hover,
[class*="bg-dark"] .link-hover-line:hover,
[class*="bg-gradient"] .link-hover-line:hover {
  color: white;
}
[class*="link-hover-gradient"] {
  z-index: 1;
  position: relative;
  color: black;
}
[class*="link-hover-gradient"]:before {
  content: "";
  z-index: -1;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.4;
  height: 30%;
  -webkit-animation: line-out 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) both;
  animation: line-out 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) both;
}
[class*="link-hover-gradient"]:hover:before {
  width: 100%;
  -webkit-animation: line-in 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) both;
  animation: line-in 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) both;
}
.link-hover-gradient-1:before {
  background: linear-gradient(45deg, #ff5988, #500c7f);
}
.link-hover-gradient-2:before {
  background: linear-gradient(45deg, #2fbdff, #0f2f40);
}
.link-hover-gradient-3:before {
  background: linear-gradient(45deg, #ff2f78, #ff7582);
}
.link-hover-gradient-4:before {
  background: linear-gradient(45deg, #00dbde, #fc00ff);
}
.link-hover-gradient-5:before {
  background: linear-gradient(45deg, #007990, #6ce5c0);
}
.display-1 [class*="link-hover-gradient"]:before,
.display-2 [class*="link-hover-gradient"]:before,
.display-3 [class*="link-hover-gradient"]:before {
  height: 20%;
}
[class*="bg-black"] [class*="link-hover-gradient"],
[class*="bg-dark"] [class*="link-hover-gradient"],
[class*="bg-gradient"] [class*="link-hover-gradient"] {
  color: white;
}
@-webkit-keyframes line-out {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }
}
@keyframes line-out {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }
}
@-webkit-keyframes line-in {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
  }
}
@keyframes line-in {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
  }
}
.link-hover-reveal {
  position: relative;
  overflow: hidden;
  display: block;
  color: black;
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.link-hover-reveal i {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  visibility: hidden;
  opacity: 0;
  color: black;
  -webkit-transition: linear 0.1s;
  transition: linear 0.1s;
}
.link-hover-reveal:hover {
  padding-left: 24px;
}
.link-hover-reveal:hover i {
  visibility: visible;
  opacity: 1;
}
h5 .link-hover-reveal:hover,
.h5 .link-hover-reveal:hover {
  padding-left: 30px;
}
h4 .link-hover-reveal:hover,
.h4 .link-hover-reveal:hover {
  padding-left: 34px;
}
h3 .link-hover-reveal:hover,
.h3 .link-hover-reveal:hover {
  padding-left: 38px;
}
h2 .link-hover-reveal:hover,
.h2 .link-hover-reveal:hover {
  padding-left: 43px;
}
h1 .link-hover-reveal:hover,
.h1 .link-hover-reveal:hover {
  padding-left: 50px;
}
[class*="bg-black"] .link-hover-reveal,
[class*="bg-dark"] .link-hover-reveal,
[class*="bg-gradient"] .link-hover-reveal {
  color: white;
}
[class*="bg-black"] .link-hover-reveal i,
[class*="bg-dark"] .link-hover-reveal i,
[class*="bg-gradient"] .link-hover-reveal i {
  color: white;
}
.link-hover-fill {
  color: black;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  -webkit-transition: linear 0.1s;
  transition: linear 0.1s;
}
.link-hover-fill:hover {
  text-shadow: 0 0 black;
}
.link-hover-stroke {
  color: black;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  -webkit-transition: linear 0.1s;
  transition: linear 0.1s;
  text-shadow: 0 0 black;
}
.link-hover-stroke:hover {
  text-shadow: none;
}
[class*="bg-black"] .link-hover-fill,
[class*="bg-black"] .link-hover-stroke,
[class*="bg-dark"] .link-hover-fill,
[class*="bg-dark"] .link-hover-stroke,
[class*="bg-gradient"] .link-hover-fill,
[class*="bg-gradient"] .link-hover-stroke {
  color: white;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}
[class*="bg-black"] .link-hover-fill:hover,
[class*="bg-dark"] .link-hover-fill:hover,
[class*="bg-gradient"] .link-hover-fill:hover {
  text-shadow: 0 0 white;
}
[class*="bg-black"] .link-hover-stroke,
[class*="bg-dark"] .link-hover-stroke,
[class*="bg-gradient"] .link-hover-stroke {
  text-shadow: 0 0 white;
}
[class*="bg-black"] .link-hover-stroke:hover,
[class*="bg-dark"] .link-hover-stroke:hover,
[class*="bg-gradient"] .link-hover-stroke:hover {
  text-shadow: none;
}
.link-hover-slide {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
}
.link-hover-slide span {
  display: block;
  position: relative;
  color: transparent;
}
.link-hover-slide span:before,
.link-hover-slide span:after {
  content: attr(data-text);
  position: absolute;
  left: 0;
  color: black;
  -webkit-transition: ease-out 0.16s;
  transition: ease-out 0.16s;
}
.link-hover-slide span:before {
  top: 0;
}
.link-hover-slide span:after {
  visibility: hidden;
  opacity: 0;
  top: 50%;
}
.link-hover-slide:hover:before {
  opacity: 0.4;
  -webkit-transform: translateY(-50%) scale(2.7);
  transform: translateY(-50%) scale(2.7);
}
.link-hover-slide:hover span:before {
  visibility: hidden;
  opacity: 0;
  top: -50%;
}
.link-hover-slide:hover span:after {
  visibility: visible;
  opacity: 1;
  top: 0;
}
[class*="bg-black"] .link-hover-slide span:before,
[class*="bg-black"] .link-hover-slide span:after,
[class*="bg-dark"] .link-hover-slide span:before,
[class*="bg-dark"] .link-hover-slide span:after,
[class*="bg-gradient"] .link-hover-slide span:before,
[class*="bg-gradient"] .link-hover-slide span:after {
  color: white;
}
.gmap {
  width: 100%;
}
.gmap-sm {
  height: 350px;
}
.gmap-md {
  height: 400px;
}
.gmap-lg {
  height: 450px;
}
@media (max-width: 991.98px) {
  .gmap-sm {
    height: 300px;
  }
  .gmap-md {
    height: 350px;
  }
  .gmap-lg {
    height: 400px;
  }
}
.process-box {
  position: relative;
  padding-left: 74px;
}
.process-box .process-order {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border: 1px dashed rgba(28, 29, 36, 0.2);
  border-radius: 50%;
  font-family: "Nunito", sans-serif;
  color: #1c1d24;
  text-align: center;
  -webkit-transition: linear 0.1s;
  transition: linear 0.1s;
}
.process-box .process-order:before {
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(45deg, #ff5988, #500c7f);
  opacity: 0;
  -webkit-transition: linear 0.1s;
  transition: linear 0.1s;
}
.process-box.process-gradient-2 .process-order:before {
  background-image: linear-gradient(45deg, #2fbdff, #0f2f40);
}
.process-box.process-gradient-3 .process-order:before {
  background-image: linear-gradient(45deg, #ff2f78, #ff7582);
}
.process-box.process-gradient-4 .process-order:before {
  background-image: linear-gradient(45deg, #00dbde, #fc00ff);
}
.process-box.process-gradient-5 .process-order:before {
  background-image: linear-gradient(45deg, #007990, #6ce5c0);
}
.process-box.active .process-order,
.process-box:hover .process-order {
  border-color: transparent;
  color: white;
}
.process-box.active .process-order:before,
.process-box:hover .process-order:before {
  opacity: 1;
}
[class*="bg-black"] .process-box .process-order,
[class*="bg-dark"] .process-box .process-order {
  border-color: rgba(255, 255, 255, 0.3);
  color: white;
}
[class*="bg-black"] .process-box.active .process-order,
[class*="bg-black"] .process-box:hover .process-order,
[class*="bg-dark"] .process-box.active .process-order,
[class*="bg-dark"] .process-box:hover .process-order {
  border-color: transparent;
}
[class*="bg-black"] .process-box.active .process-order:before,
[class*="bg-black"] .process-box:hover .process-order:before,
[class*="bg-dark"] .process-box.active .process-order:before,
[class*="bg-dark"] .process-box:hover .process-order:before {
  opacity: 1;
}
@media (max-width: 991.98px) {
  .process-box {
    padding-left: 70px;
  }
}
.progress-box .animated-progress {
  position: relative;
  background: rgba(28, 29, 36, 0.1);
  height: 4px;
  margin-top: 4px;
  border-radius: 3px;
}
.progress-box .animated-progress div {
  position: absolute;
  display: block;
  background: #1c1d24;
  width: 10%;
  height: 100%;
  border-radius: 4px;
  color: #1c1d24;
  -webkit-transition: width 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: width 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.progress-box .animated-progress div:after {
  content: attr(data-progress) "%";
  position: absolute;
  top: -30px;
  right: 0;
  opacity: 0;
  font-family: "Nunito", sans-serif;
  font-size: 500;
  -webkit-transition: ease-out 0.16s;
  transition: ease-out 0.16s;
}
.progress-box .animated-progress div.progress-show:after {
  opacity: 1;
}
[class*="bg-black"] .progress-box .animated-progress,
[class*="bg-dark"] .progress-box .animated-progress,
[class*="bg-gradient"] .progress-box .animated-progress {
  background: rgba(255, 255, 255, 0.1);
}
[class*="bg-black"] .progress-box .animated-progress div,
[class*="bg-dark"] .progress-box .animated-progress div,
[class*="bg-gradient"] .progress-box .animated-progress div {
  background: white;
  color: white;
}
.progress-box.progress-gradient-1 .animated-progress div {
  background: transparent;
  background-image: linear-gradient(45deg, #ff5988, #500c7f);
}
.progress-box.progress-gradient-2 .animated-progress div {
  background: transparent;
  background-image: linear-gradient(45deg, #2fbdff, #0f2f40);
}
.progress-box.progress-gradient-3 .animated-progress div {
  background: transparent;
  background-image: linear-gradient(45deg, #ff2f78, #ff7582);
}
.progress-box.progress-gradient-4 .animated-progress div {
  background: transparent;
  background-image: linear-gradient(45deg, #00dbde, #fc00ff);
}
.progress-box.progress-gradient-5 .animated-progress div {
  background: transparent;
  background-image: linear-gradient(45deg, #007990, #6ce5c0);
}
.sliding-text .swiper-slide,
.sliding-text-reverse .swiper-slide {
  width: auto;
}
.sliding-boxes .swiper-wrapper,
.sliding-text .swiper-wrapper,
.sliding-text-reverse .swiper-wrapper {
  transition-timing-function: linear;
}
.sliding-boxes .swiper-slide {
  max-width: 20%;
}
@media (max-width: 1399.98px) {
  .sliding-boxes .swiper-slide {
    max-width: 25%;
  }
}
@media (max-width: 991.98px) {
  .sliding-boxes .swiper-slide {
    max-width: 30%;
  }
}
@media (max-width: 767.98px) {
  .sliding-boxes .swiper-slide {
    max-width: 50%;
  }
}
@media (max-width: 575.98px) {
  .sliding-boxes .swiper-slide {
    max-width: 70%;
  }
}
.portfolio-horizontal-slider.swiper,
.portfolio-horizontal-slider.swiper-container {
  overflow: visible;
}
.swiper-pagination-bullet {
  position: relative;
  display: inline-block;
  opacity: 1;
  background: transparent;
  width: 24px;
  height: 24px;
  margin: 0 2px;
  vertical-align: middle;
}
.swiper-pagination-bullet:before,
.swiper-pagination-bullet:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  -webkit-transition: ease-out 0.16s;
  transition: ease-out 0.16s;
}
.swiper-pagination-bullet:before {
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  opacity: 0;
  background: transparent;
  border: 1px solid black;
}
.swiper-pagination-bullet:after {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0.3;
  background: black;
  width: 5px;
  height: 5px;
}
.swiper-pagination-bullet:hover:after,
.swiper-pagination-bullet:focus:after,
.swiper-pagination-bullet.swiper-pagination-bullet-active:after {
  opacity: 1;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active:after {
  opacity: 1;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active:before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.4;
}
[class*="bg-black"] .swiper-pagination-bullet:before,
[class*="bg-dark"] .swiper-pagination-bullet:before,
[class*="bg-gradient"] .swiper-pagination-bullet:before {
  border-color: white;
}
[class*="bg-black"] .swiper-pagination-bullet:after,
[class*="bg-dark"] .swiper-pagination-bullet:after,
[class*="bg-gradient"] .swiper-pagination-bullet:after {
  background: white;
  opacity: 0.5;
}
[class*="bg-black"] .swiper-pagination-bullet:hover:after,
[class*="bg-black"] .swiper-pagination-bullet:focus:after,
[class*="bg-black"] .swiper-pagination-bullet.swiper-pagination-bullet-active:after,
[class*="bg-dark"] .swiper-pagination-bullet:hover:after,
[class*="bg-dark"] .swiper-pagination-bullet:focus:after,
[class*="bg-dark"] .swiper-pagination-bullet.swiper-pagination-bullet-active:after,
[class*="bg-gradient"] .swiper-pagination-bullet:hover:after,
[class*="bg-gradient"] .swiper-pagination-bullet:focus:after,
[class*="bg-gradient"] .swiper-pagination-bullet.swiper-pagination-bullet-active:after {
  opacity: 1;
}
.horizontal-portfolio-pagination.swiper-pagination-horizontal {
  background: rgba(28, 29, 36, 0.3);
  width: auto;
  border-radius: 20px;
  margin: 0;
  padding: 5px;
}
@media (max-width: 991.98px) {
  .horizontal-portfolio-pagination.swiper-pagination-horizontal {
    bottom: 16px;
  }
}
@media (max-width: 991.98px) {
  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 2px;
  }
  .horizontal-portfolio-pagination .swiper-pagination-bullet {
    width: 17px;
    height: 17px;
  }
}
.blog-slider-featured-3-pagination {
  text-align: center;
}
.blog-slider-featured-3-pagination.swiper-pagination-horizontal {
  width: auto;
  border-radius: 20px;
  margin: 0;
  padding: 0;
}
@media (max-width: 991.98px) {
  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 2px;
  }
  .blog-slider-featured-3-pagination .swiper-pagination-bullet {
    width: 17px;
    height: 17px;
  }
}
.testimonial-single-wrapper {
  position: relative;
  padding: 0 80px;
}
@media (max-width: 991.98px) {
  .testimonial-single-wrapper {
    padding: 0 66px;
  }
}
.testimonial-single-nav {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}
.team-box {
  position: relative;
}
.team-box .team-img {
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.team-box .team-img img {
  -webkit-transform: scale(1);
  transform: scale(1);
  width: 100%;
  -webkit-transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.team-box .team-hover {
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  -webkit-transition: linear 0.1s;
  transition: linear 0.1s;
}
.team-box:hover .team-img img {
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
}
.team-box:hover .team-hover {
  opacity: 1;
  visibility: visible;
}
.testimonial-quote-box .quote {
  background: #f1f2fa;
  padding: 24px;
  border-radius: 1em;
}
.testimonial-quote-box .quote-author {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  padding-top: 26px;
}
.testimonial-quote-box .quote-author:before,
.testimonial-quote-box .quote-author:after {
  content: "";
  position: absolute;
  background: #f1f2fa;
  border-radius: 50%;
}
.testimonial-quote-box .quote-author:before {
  top: 14px;
  left: 40px;
  width: 8px;
  height: 8px;
}
.testimonial-quote-box .quote-author:after {
  top: -10px;
  left: 44px;
  width: 20px;
  height: 20px;
}
.testimonial-quote-box .quote-author img {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.bg-gray .testimonial-quote-box .quote,
.bg-iceblue .testimonial-quote-box .quote {
  background: white;
}
.bg-gray .testimonial-quote-box .quote-author:before,
.bg-gray .testimonial-quote-box .quote-author:after,
.bg-iceblue .testimonial-quote-box .quote-author:before,
.bg-iceblue .testimonial-quote-box .quote-author:after {
  background: white;
}
[class*="bg-black"] .testimonial-quote-box .quote,
[class*="bg-dark"] .testimonial-quote-box .quote,
[class*="bg-gradient"] .testimonial-quote-box .quote {
  background: rgba(255, 255, 255, 0.1);
}
[class*="bg-black"] .testimonial-quote-box .quote-author,
[class*="bg-dark"] .testimonial-quote-box .quote-author,
[class*="bg-gradient"] .testimonial-quote-box .quote-author {
  padding-top: 16px;
}
[class*="bg-black"] .testimonial-quote-box .quote-author:before,
[class*="bg-black"] .testimonial-quote-box .quote-author:after,
[class*="bg-dark"] .testimonial-quote-box .quote-author:before,
[class*="bg-dark"] .testimonial-quote-box .quote-author:after,
[class*="bg-gradient"] .testimonial-quote-box .quote-author:before,
[class*="bg-gradient"] .testimonial-quote-box .quote-author:after {
  display: none;
}
[class*="text-highlight"] {
  position: relative;
  display: inline-block;
}
[class*="text-highlight"]:before {
  z-index: -1;
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 10%;
}
.text-highlight:before {
  bottom: 0;
  background: rgba(28, 29, 36, 0.2);
}
[class*="bg-black"] .text-highlight:before,
[class*="bg-dark"] .text-highlight:before,
[class*="bg-gradient"] .text-highlight:before {
  background: rgba(255, 255, 255, 0.2);
}
[class*="text-highlight-gradient"]:before {
  bottom: 5%;
}
.text-highlight-gradient-1:before {
  background-image: linear-gradient(45deg, #ff5988, #500c7f);
}
.text-highlight-gradient-2:before {
  background-image: linear-gradient(45deg, #2fbdff, #0f2f40);
}
.text-highlight-gradient-3:before {
  background-image: linear-gradient(45deg, #ff2f78, #ff7582);
}
.text-highlight-gradient-4:before {
  background-image: linear-gradient(45deg, #00dbde, #fc00ff);
}
.text-highlight-gradient-5:before {
  background-image: linear-gradient(45deg, #007990, #6ce5c0);
}
.title-fade-gradient-1,
.title-fade-gradient-2,
.title-fade-gradient-3,
.title-fade-gradient-4,
.title-fade-gradient-5,
.title-fade-dark {
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.title-fade-gradient-1:before,
.title-fade-gradient-2:before,
.title-fade-gradient-3:before,
.title-fade-gradient-4:before,
.title-fade-gradient-5:before,
.title-fade-dark:before {
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.15;
  width: 100%;
  height: 100%;
}
.title-fade-gradient-1:before {
  background-image: linear-gradient(90deg, #ff5988, transparent 90%);
}
.title-fade-gradient-2:before {
  background-image: linear-gradient(90deg, #2fbdff, transparent 90%);
}
.title-fade-gradient-3:before {
  background-image: linear-gradient(90deg, #ff2f78, transparent 90%);
}
.title-fade-gradient-4:before {
  background-image: linear-gradient(90deg, #00dbde, transparent 90%);
}
.title-fade-gradient-5:before {
  background-image: linear-gradient(90deg, #007990, transparent 90%);
}
.title-fade-dark:before {
  background-image: linear-gradient(90deg, rgba(28, 29, 36, 0.5), transparent 90%);
}
.border-grid {
  list-style-type: none;
  overflow: hidden;
  margin: 0;
  padding: 0;
  text-align: center;
}
.border-grid li {
  position: relative;
  float: left;
  width: 16.6%;
  margin: 0;
  padding: 30px;
}
.border-grid li:before,
.border-grid li:after {
  content: "";
  position: absolute;
}
.border-grid li:before {
  top: 0;
  left: -1px;
  height: 100%;
  border-left: 1px dashed rgba(28, 29, 36, 0.2);
}
.border-grid li:after {
  left: 0;
  bottom: -1px;
  width: 100%;
  border-bottom: 1px dashed rgba(28, 29, 36, 0.2);
}
@media (max-width: 991.98px) {
  .border-grid li {
    width: 25%;
  }
}
@media (max-width: 767.98px) {
  .border-grid li {
    width: 33.3%;
  }
}
@media (max-width: 575.98px) {
  .border-grid li {
    width: 50%;
  }
}
.border-grid.column-5 li {
  width: 20%;
}
@media (max-width: 991.98px) {
  .border-grid.column-5 li {
    width: 25%;
  }
}
@media (max-width: 767.98px) {
  .border-grid.column-5 li {
    width: 33.3%;
  }
}
@media (max-width: 575.98px) {
  .border-grid.column-5 li {
    width: 50%;
  }
}
.border-grid.column-4 li {
  width: 25%;
}
@media (max-width: 767.98px) {
  .border-grid.column-4 li {
    width: 33.3%;
  }
}
@media (max-width: 575.98px) {
  .border-grid.column-4 li {
    width: 50%;
  }
}
.border-grid.column-3 li {
  width: 33.3%;
}
@media (max-width: 767.98px) {
  .border-grid.column-3 li {
    width: 50%;
  }
}
.border-grid.column-2 li {
  width: 50%;
}
.border-grid.border-style-solid {
  border: 0;
}
.border-grid.border-style-solid li:before {
  border-left-style: solid;
}
.border-grid.border-style-solid li:after {
  border-bottom-style: solid;
}
.border-grid.border-style-dotted {
  border: 0;
}
.border-grid.border-style-dotted li:before {
  border-left-style: dotted;
}
.border-grid.border-style-dotted li:after {
  border-bottom-style: dotted;
}
[class*="bg-black"] .border-grid li:before,
[class*="bg-black"] .border-grid li:after,
[class*="bg-dark"] .border-grid li:before,
[class*="bg-dark"] .border-grid li:after,
[class*="bg-gradient"] .border-grid li:before,
[class*="bg-gradient"] .border-grid li:after {
  border-color: rgba(255, 255, 255, 0.2);
}
.header .fm-toggle {
  display: inline-block;
  position: relative;
  background: transparent;
  border: 0;
  color: black;
  font-family: "Nunito", sans-serif;
}
.header .fm-toggle span {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 10px;
  margin-left: 10px;
}
.header .fm-toggle span:before,
.header .fm-toggle span:after {
  content: "";
  position: absolute;
  right: 0;
  background: black;
  height: 2px;
  border-radius: 2px;
  -webkit-transition: linear 0.1s;
  transition: linear 0.1s;
}
.header .fm-toggle span:before {
  top: 0;
  width: 70%;
}
.header .fm-toggle span:after {
  bottom: 0;
  width: 100%;
}
.header .fm-toggle:hover span:before {
  width: 100%;
}
.header.transparent-light .fm-toggle,
.header.header-dark .fm-toggle {
  color: white;
}
.header.transparent-light .fm-toggle span:before,
.header.transparent-light .fm-toggle span:after,
.header.header-dark .fm-toggle span:before,
.header.header-dark .fm-toggle span:after {
  background: white;
}
.header.logo-center .container,
.header.logo-center .container-fluid {
  justify-content: space-between;
}
.header.logo-center .header-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.header.logo-center .header-extra {
  margin: 0;
}
.fullscreen-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  visibility: hidden;
  opacity: 0;
  background: black;
  width: 100%;
  height: 100%;
  -webkit-transition: ease-out 0.2s;
  transition: ease-out 0.2s;
}
.fullscreen-menu.show {
  visibility: visible;
  opacity: 1;
}
.fullscreen-menu .fm-close {
  position: absolute;
  top: 50px;
  right: 50px;
}
.fullscreen-menu .fm-close i {
  -webkit-transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.fullscreen-menu .fm-close:hover i {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.header {
  z-index: 997;
  width: 100%;
  height: 80px;
  -webkit-transition: ease-in-out 0.2s;
  transition: ease-in-out 0.2s;
}
.header .container,
.header .container-fluid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  height: 100%;
}
.header.hide {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.header .header-logo {
  margin-right: auto;
}
.header .header-logo * {
  margin: 0;
}
.header .header-menu .nav {
  font-family: "Nunito", sans-serif;
  font-weight: 500;
}
.header .header-menu .nav .nav-item {
  position: relative;
}
.header .header-menu .nav .nav-item .nav-link {
  color: black;
}
.header .header-menu .nav .nav-item .nav-link:hover {
  color: rgba(0, 0, 0, 0.8);
}
.header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item {
  list-style-type: none;
}
.header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item .nav-subdropdown .nav-subdropdown-item {
  list-style-type: none;
}
.header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item .nav-subdropdown-toggle {
  position: absolute;
  font-size: 0.85em;
}
.header .header-menu .nav .nav-item .nav-dropdown-toggle,
.header .header-menu .nav .nav-item .nav-megadropdown-toggle {
  font-size: 0.85em;
}
.header.header-white {
  background: rgba(255, 255, 255, 0.9);
}
.header.header-dark {
  background: rgba(0, 0, 0, 0.85);
}
.header.header-dark:not(.transparent-dark) .header-logo,
.header.transparent-light .header-logo {
  color: white;
}
.header.header-dark:not(.transparent-dark) .header-logo h1,
.header.header-dark:not(.transparent-dark) .header-logo h2,
.header.header-dark:not(.transparent-dark) .header-logo h3,
.header.header-dark:not(.transparent-dark) .header-logo h4,
.header.header-dark:not(.transparent-dark) .header-logo h5,
.header.header-dark:not(.transparent-dark) .header-logo h6,
.header.header-dark:not(.transparent-dark) .header-logo i,
.header.transparent-light .header-logo h1,
.header.transparent-light .header-logo h2,
.header.transparent-light .header-logo h3,
.header.transparent-light .header-logo h4,
.header.transparent-light .header-logo h5,
.header.transparent-light .header-logo h6,
.header.transparent-light .header-logo i {
  color: white;
}
.header.header-dark:not(.transparent-dark) .header-logo a,
.header.transparent-light .header-logo a {
  color: white;
}
.header.header-dark:not(.transparent-dark) .header-logo a i,
.header.transparent-light .header-logo a i {
  color: white;
}
.header.header-dark:not(.transparent-dark) .header-logo a:hover,
.header.header-dark:not(.transparent-dark) .header-logo a:focus,
.header.transparent-light .header-logo a:hover,
.header.transparent-light .header-logo a:focus {
  color: white;
}
.header.header-dark:not(.transparent-dark) .header-logo a:hover i,
.header.header-dark:not(.transparent-dark) .header-logo a:focus i,
.header.transparent-light .header-logo a:hover i,
.header.transparent-light .header-logo a:focus i {
  color: white;
}
.header.header-dark:not(.transparent-dark) .header-extra,
.header.transparent-light .header-extra {
  color: rgba(255, 255, 255, 0.8);
}
.header.header-dark:not(.transparent-dark) .header-extra h1,
.header.header-dark:not(.transparent-dark) .header-extra h2,
.header.header-dark:not(.transparent-dark) .header-extra h3,
.header.header-dark:not(.transparent-dark) .header-extra h4,
.header.header-dark:not(.transparent-dark) .header-extra h5,
.header.header-dark:not(.transparent-dark) .header-extra h6,
.header.header-dark:not(.transparent-dark) .header-extra i,
.header.header-dark:not(.transparent-dark) .header-extra .h1,
.header.header-dark:not(.transparent-dark) .header-extra .h2,
.header.header-dark:not(.transparent-dark) .header-extra .h3,
.header.header-dark:not(.transparent-dark) .header-extra .h4,
.header.header-dark:not(.transparent-dark) .header-extra .h5,
.header.header-dark:not(.transparent-dark) .header-extra .h6,
.header.transparent-light .header-extra h1,
.header.transparent-light .header-extra h2,
.header.transparent-light .header-extra h3,
.header.transparent-light .header-extra h4,
.header.transparent-light .header-extra h5,
.header.transparent-light .header-extra h6,
.header.transparent-light .header-extra i,
.header.transparent-light .header-extra .h1,
.header.transparent-light .header-extra .h2,
.header.transparent-light .header-extra .h3,
.header.transparent-light .header-extra .h4,
.header.transparent-light .header-extra .h5,
.header.transparent-light .header-extra .h6 {
  color: white;
}
.header.header-dark:not(.transparent-dark) .header-extra a:not([class^="button"]):not([class^="link-hover"]),
.header.transparent-light .header-extra a:not([class^="button"]):not([class^="link-hover"]) {
  color: white;
}
.header.header-dark:not(.transparent-dark) .header-extra a:not([class^="button"]):not([class^="link-hover"]) i,
.header.transparent-light .header-extra a:not([class^="button"]):not([class^="link-hover"]) i {
  color: white;
}
.header.header-dark:not(.transparent-dark) .header-extra a:not([class^="button"]):not([class^="link-hover"]):hover,
.header.header-dark:not(.transparent-dark) .header-extra a:not([class^="button"]):not([class^="link-hover"]):focus,
.header.transparent-light .header-extra a:not([class^="button"]):not([class^="link-hover"]):hover,
.header.transparent-light .header-extra a:not([class^="button"]):not([class^="link-hover"]):focus {
  color: rgba(255, 255, 255, 0.8);
}
.header.header-dark:not(.transparent-dark) .header-extra a:not([class^="button"]):not([class^="link-hover"]):hover i,
.header.header-dark:not(.transparent-dark) .header-extra a:not([class^="button"]):not([class^="link-hover"]):focus i,
.header.transparent-light .header-extra a:not([class^="button"]):not([class^="link-hover"]):hover i,
.header.transparent-light .header-extra a:not([class^="button"]):not([class^="link-hover"]):focus i {
  color: rgba(255, 255, 255, 0.8);
}
.header.transparent-light,
.header.transparent-dark {
  background: transparent;
}
.header.transparent-light:not(.sticky-autohide):not(.sticky),
.header.transparent-dark:not(.sticky-autohide):not(.sticky) {
  position: absolute;
  top: 0;
  left: 0;
}
.header.sticky-autohide,
.header.sticky {
  position: fixed;
  top: 0;
  left: 0;
}
@media (min-width: 992px) {
  .header .header-logo {
    z-index: 998;
  }
  .header .header-menu {
    margin-left: auto;
  }
  .header .header-menu .nav .nav-item {
    margin-right: 24px;
    height: 80px;
    display: inline-flex;
    align-items: center;
  }
  .header .header-menu .nav .nav-item:last-child {
    margin-right: 0;
  }
  .header .header-menu .nav .nav-item .nav-link {
    position: relative;
    padding: 0;
  }
  .header .header-menu.nav-link-hover-line .nav .nav-item .nav-link:before {
    content: "";
    position: absolute;
    right: 0;
    bottom: -1px;
    left: 0;
    background: black;
    height: 1px;
    -webkit-animation: line-out 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) both;
    animation: line-out 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) both;
  }
  .header .header-menu.nav-link-hover-line .nav .nav-item .nav-link:hover {
    color: black;
  }
  .header .header-menu.nav-link-hover-line .nav .nav-item .nav-link:hover:before {
    width: 100%;
    -webkit-animation: line-in 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) both;
    animation: line-in 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) both;
  }
  .header .header-menu.nav-link-hover-slide .nav .nav-item .nav-link {
    position: relative;
    color: transparent;
  }
  .header .header-menu.nav-link-hover-slide .nav .nav-item .nav-link:before,
  .header .header-menu.nav-link-hover-slide .nav .nav-item .nav-link:after {
    content: attr(data-text);
    position: absolute;
    left: 0;
    color: #1c1d24;
    -webkit-transition: ease-out 0.16s;
    transition: ease-out 0.16s;
  }
  .header .header-menu.nav-link-hover-slide .nav .nav-item .nav-link:before {
    top: 0;
  }
  .header .header-menu.nav-link-hover-slide .nav .nav-item .nav-link:after {
    visibility: hidden;
    opacity: 0;
    top: 50%;
  }
  .header .header-menu.nav-link-hover-slide .nav .nav-item .nav-link:hover:before {
    visibility: hidden;
    opacity: 0;
    top: -50%;
  }
  .header .header-menu.nav-link-hover-slide .nav .nav-item .nav-link:hover:after {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
  .header .header-menu .nav .nav-item .nav-dropdown-toggle,
  .header .header-menu .nav .nav-item .nav-megadropdown-toggle {
    display: none;
  }
  .header .header-menu .nav .nav-item .nav-dropdown {
    z-index: 998;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 80px;
    right: 0;
    background: white;
    width: 250px;
    padding: 28px 0;
    -webkit-box-shadow: 0 8px 34px 0 rgba(22, 24, 26, 0.13);
    box-shadow: 0 8px 34px 0 rgba(22, 24, 26, 0.13);
    -webkit-transition: linear 0.06s;
    transition: linear 0.06s;
  }
  .header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item {
    position: relative;
    list-style-type: none;
    padding: 0 30px;
    margin-bottom: 10px;
  }
  .header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item.dropdown-icon-space {
    padding-right: 46px;
  }
  .header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item:last-child {
    margin-bottom: 0;
  }
  .header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item .nav-dropdown-link {
    display: block;
    width: 100%;
    color: rgba(28, 29, 36, 0.7);
  }
  .header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item .nav-dropdown-link:hover {
    color: #1c1d24;
  }
  .header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item .nav-subdropdown {
    position: absolute;
    top: -28px;
    right: 100%;
    visibility: hidden;
    opacity: 0;
    background: white;
    width: 250px;
    padding: 28px 0;
    -webkit-box-shadow: 0 8px 34px 0 rgba(22, 24, 26, 0.13);
    box-shadow: 0 8px 34px 0 rgba(22, 24, 26, 0.13);
    -webkit-transition: linear 0.06s;
    transition: linear 0.06s;
  }
  .header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item .nav-subdropdown .nav-subdropdown-item {
    margin-bottom: 10px;
    padding: 0 30px;
  }
  .header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item .nav-subdropdown .nav-subdropdown-item:last-child {
    margin-bottom: 0;
  }
  .header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item .nav-subdropdown .nav-subdropdown-item .nav-subdropdown-link {
    display: block;
    width: 100%;
    color: rgba(28, 29, 36, 0.7);
  }
  .header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item .nav-subdropdown .nav-subdropdown-item .nav-subdropdown-link:hover {
    color: #1c1d24;
  }
  .header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item .nav-subdropdown-toggle {
    top: 50%;
    right: 30px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item .nav-subdropdown-toggle i {
    color: rgba(28, 29, 36, 0.7);
  }
  .header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item:hover .nav-subdropdown-toggle i {
    color: #1c1d24;
  }
  .header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item:hover .nav-subdropdown {
    visibility: visible;
    opacity: 1;
  }
  .header .header-menu .nav .nav-item .nav-dropdown.style-2 {
    padding: 0;
  }
  .header .header-menu .nav .nav-item .nav-dropdown.style-2 .nav-dropdown-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding: 16px 30px;
    border-bottom: 1px dashed rgba(28, 29, 36, 0.2);
  }
  .header .header-menu .nav .nav-item .nav-dropdown.style-2 .nav-dropdown-item:last-child {
    border-bottom: 0;
  }
  .header .header-menu .nav .nav-item .nav-dropdown.style-2 .nav-dropdown-item .nav-subdropdown {
    padding: 0;
  }
  .header .header-menu .nav .nav-item .nav-dropdown.style-2 .nav-dropdown-item .nav-subdropdown .nav-subdropdown-item {
    margin: 0;
    padding: 16px 30px;
    border-bottom: 1px dashed rgba(28, 29, 36, 0.2);
  }
  .header .header-menu .nav .nav-item .nav-dropdown.style-2 .nav-dropdown-item .nav-subdropdown .nav-subdropdown-item:last-child {
    border-bottom: 0;
  }
  .header .header-menu .nav .nav-item:hover .nav-dropdown {
    visibility: visible;
    opacity: 1;
  }
  .header .header-menu .nav .nav-item.mega-nav-item {
    position: static;
  }
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown {
    z-index: 998;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 80px;
    right: 0;
    background: white;
    width: 100%;
    padding: 30px;
    color: #787a7c;
    -webkit-box-shadow: 0 8px 34px 0 rgba(22, 24, 26, 0.13);
    box-shadow: 0 8px 34px 0 rgba(22, 24, 26, 0.13);
    -webkit-transition: linear 0.06s;
    transition: linear 0.06s;
  }
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown h1,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown h2,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown h3,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown h4,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown h5,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown h6,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown i,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown .h1,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown .h2,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown .h3,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown .h4,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown .h5,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown .h6 {
    color: #1c1d24;
  }
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown a:not([class^="button"]):not([class^="link-hover"]) {
    color: rgba(28, 29, 36, 0.8);
  }
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown a:not([class^="button"]):not([class^="link-hover"]):hover,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown a:not([class^="button"]):not([class^="link-hover"]):focus {
    color: #1c1d24;
  }
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown.mega-nav-dropdown-dark {
    background: rgba(28, 29, 36, 0.9);
    color: rgba(255, 255, 255, 0.8);
  }
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown.mega-nav-dropdown-dark h1,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown.mega-nav-dropdown-dark h2,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown.mega-nav-dropdown-dark h3,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown.mega-nav-dropdown-dark h4,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown.mega-nav-dropdown-dark h5,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown.mega-nav-dropdown-dark h6,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown.mega-nav-dropdown-dark i,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown.mega-nav-dropdown-dark .h1,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown.mega-nav-dropdown-dark .h2,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown.mega-nav-dropdown-dark .h3,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown.mega-nav-dropdown-dark .h4,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown.mega-nav-dropdown-dark .h5,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown.mega-nav-dropdown-dark .h6 {
    color: white;
  }
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown.mega-nav-dropdown-dark a:not([class^="button"]):not([class^="link-hover"]) {
    color: rgba(255, 255, 255, 0.8);
  }
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown.mega-nav-dropdown-dark a:not([class^="button"]):not([class^="link-hover"]) i {
    color: rgba(255, 255, 255, 0.8);
  }
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown.mega-nav-dropdown-dark a:not([class^="button"]):not([class^="link-hover"]):hover,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown.mega-nav-dropdown-dark a:not([class^="button"]):not([class^="link-hover"]):focus {
    color: white;
  }
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown.mega-nav-dropdown-dark a:not([class^="button"]):not([class^="link-hover"]):hover i,
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown.mega-nav-dropdown-dark a:not([class^="button"]):not([class^="link-hover"]):focus i {
    color: white;
  }
  .header .header-menu .nav .nav-item.mega-nav-item:hover .mega-nav-dropdown {
    visibility: visible;
    opacity: 1;
  }
  .header .header-menu .nav.dropdown-dark .nav-item .nav-dropdown {
    background: rgba(28, 29, 36, 0.9);
    color: white;
    font-weight: 400;
  }
  .header .header-menu .nav.dropdown-dark .nav-item .nav-dropdown a,
  .header .header-menu .nav.dropdown-dark .nav-item .nav-dropdown i {
    color: white;
  }
  .header .header-menu .nav.dropdown-dark .nav-item .nav-dropdown .nav-dropdown-item .nav-dropdown-link {
    color: rgba(255, 255, 255, 0.8);
  }
  .header .header-menu .nav.dropdown-dark .nav-item .nav-dropdown .nav-dropdown-item .nav-dropdown-link:hover {
    color: white;
  }
  .header .header-menu .nav.dropdown-dark .nav-item .nav-dropdown .nav-dropdown-item .nav-subdropdown-toggle i {
    color: rgba(255, 255, 255, 0.8);
  }
  .header .header-menu .nav.dropdown-dark .nav-item .nav-dropdown .nav-dropdown-item .nav-subdropdown {
    background: rgba(28, 29, 36, 0.9);
    color: white;
  }
  .header .header-menu .nav.dropdown-dark .nav-item .nav-dropdown .nav-dropdown-item .nav-subdropdown .nav-subdropdown-item .nav-subdropdown-link {
    color: rgba(255, 255, 255, 0.8);
  }
  .header .header-menu .nav.dropdown-dark .nav-item .nav-dropdown .nav-dropdown-item .nav-subdropdown .nav-subdropdown-item .nav-subdropdown-link:hover {
    color: white;
  }
  .header .header-menu .nav.dropdown-dark .nav-item .nav-dropdown .nav-dropdown-item:hover .nav-subdropdown-toggle i {
    color: white;
  }
  .header .header-menu .nav.dropdown-dark .nav-item .nav-dropdown.style-2 .nav-dropdown-item {
    border-color: rgba(255, 255, 255, 0.2);
  }
  .header .header-menu .nav.dropdown-dark .nav-item .nav-dropdown.style-2 .nav-dropdown-item .nav-subdropdown .nav-subdropdown-item {
    border-color: rgba(255, 255, 255, 0.2);
  }
  .header .header-extra {
    z-index: 998;
    margin-left: auto;
  }
  .header.nav-left .header-logo {
    margin-right: 30px;
  }
  .header.nav-left .header-menu {
    margin-right: auto;
    margin-left: 0;
  }
  .header.nav-left .header-menu .nav .nav-item .nav-dropdown {
    right: auto;
    left: 0;
  }
  .header.nav-left .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item .nav-subdropdown {
    right: auto;
    left: 100%;
  }
  .header.nav-left .header-menu .nav .nav-item .nav-dropdown,
  .header.nav-center .header-menu .nav .nav-item .nav-dropdown {
    right: auto;
    left: 0;
  }
  .header.nav-left .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item .nav-subdropdown,
  .header.nav-center .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item .nav-subdropdown {
    right: auto;
    left: 100%;
  }
  .header.nav-center .nav {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .header.nav-center .nav .nav-item {
    text-align: left;
  }
  .header.header-dark:not(.transparent-dark),
  .header.transparent-light {
    color: rgba(255, 255, 255, 0.8);
  }
  .header.header-dark:not(.transparent-dark) h1,
  .header.header-dark:not(.transparent-dark) h2,
  .header.header-dark:not(.transparent-dark) h3,
  .header.header-dark:not(.transparent-dark) h4,
  .header.header-dark:not(.transparent-dark) h5,
  .header.header-dark:not(.transparent-dark) h6,
  .header.header-dark:not(.transparent-dark) i,
  .header.header-dark:not(.transparent-dark) .h1,
  .header.header-dark:not(.transparent-dark) .h2,
  .header.header-dark:not(.transparent-dark) .h3,
  .header.header-dark:not(.transparent-dark) .h4,
  .header.header-dark:not(.transparent-dark) .h5,
  .header.header-dark:not(.transparent-dark) .h6,
  .header.transparent-light h1,
  .header.transparent-light h2,
  .header.transparent-light h3,
  .header.transparent-light h4,
  .header.transparent-light h5,
  .header.transparent-light h6,
  .header.transparent-light i,
  .header.transparent-light .h1,
  .header.transparent-light .h2,
  .header.transparent-light .h3,
  .header.transparent-light .h4,
  .header.transparent-light .h5,
  .header.transparent-light .h6 {
    color: white;
  }
  .header.header-dark:not(.transparent-dark) a:not([class^="button"]):not([class^="link-hover"]),
  .header.transparent-light a:not([class^="button"]):not([class^="link-hover"]) {
    color: white;
  }
  .header.header-dark:not(.transparent-dark) a:not([class^="button"]):not([class^="link-hover"]) i,
  .header.transparent-light a:not([class^="button"]):not([class^="link-hover"]) i {
    color: white;
  }
  .header.header-dark:not(.transparent-dark) a:not([class^="button"]):not([class^="link-hover"]):hover,
  .header.header-dark:not(.transparent-dark) a:not([class^="button"]):not([class^="link-hover"]):focus,
  .header.transparent-light a:not([class^="button"]):not([class^="link-hover"]):hover,
  .header.transparent-light a:not([class^="button"]):not([class^="link-hover"]):focus {
    color: rgba(255, 255, 255, 0.8);
  }
  .header.header-dark:not(.transparent-dark) a:not([class^="button"]):not([class^="link-hover"]):hover i,
  .header.header-dark:not(.transparent-dark) a:not([class^="button"]):not([class^="link-hover"]):focus i,
  .header.transparent-light a:not([class^="button"]):not([class^="link-hover"]):hover i,
  .header.transparent-light a:not([class^="button"]):not([class^="link-hover"]):focus i {
    color: rgba(255, 255, 255, 0.8);
  }
  .header.header-dark:not(.transparent-dark) .header-menu .nav .nav-item .nav-link,
  .header.transparent-light .header-menu .nav .nav-item .nav-link {
    color: white;
  }
  .header.header-dark:not(.transparent-dark) .header-menu .nav .nav-item .nav-link:hover,
  .header.transparent-light .header-menu .nav .nav-item .nav-link:hover {
    color: rgba(255, 255, 255, 0.8);
  }
  .header.header-dark:not(.transparent-dark) .header-menu.nav-link-hover-line .nav .nav-item .nav-link:before,
  .header.transparent-light .header-menu.nav-link-hover-line .nav .nav-item .nav-link:before {
    background: white;
  }
  .header.header-dark:not(.transparent-dark) .header-menu.nav-link-hover-line .nav .nav-item .nav-link:hover,
  .header.transparent-light .header-menu.nav-link-hover-line .nav .nav-item .nav-link:hover {
    color: white;
  }
  .header.header-dark:not(.transparent-dark) .header-menu.nav-link-hover-slide .nav .nav-item .nav-link:before,
  .header.header-dark:not(.transparent-dark) .header-menu.nav-link-hover-slide .nav .nav-item .nav-link:after,
  .header.transparent-light .header-menu.nav-link-hover-slide .nav .nav-item .nav-link:before,
  .header.transparent-light .header-menu.nav-link-hover-slide .nav .nav-item .nav-link:after {
    color: white;
  }
  .header.header-dark:not(.transparent-dark) .header-menu.nav-link-hover-line .nav .nav-item .nav-link:before,
  .header.transparent-light .header-menu.nav-link-hover-line .nav .nav-item .nav-link:before {
    background: white;
  }
  .header.header-dark:not(.transparent-dark) .header-menu.nav-link-hover-line .nav .nav-item .nav-link:hover,
  .header.transparent-light .header-menu.nav-link-hover-line .nav .nav-item .nav-link:hover {
    color: white;
  }
  .header.header-dark:not(.transparent-dark) .header-menu .nav,
  .header.transparent-light .header-menu .nav {
    font-weight: 400;
  }
  .header.transparent-light,
  .header.transparent-dark {
    backdrop-filter: none;
  }
  .header.header-lg {
    height: 90px;
  }
  .header.header-lg .header-menu .nav .nav-item {
    height: 90px;
  }
  .header.header-lg .header-menu .nav .nav-item .nav-dropdown,
  .header.header-lg .header-menu .nav .nav-item .mega-nav-dropdown {
    top: 90px;
  }
  .header.header-xl {
    height: 100px;
  }
  .header.header-xl .header-menu .nav .nav-item {
    height: 100px;
  }
  .header.header-xl .header-menu .nav .nav-item .nav-dropdown,
  .header.header-xl .header-menu .nav .nav-item .mega-nav-dropdown {
    top: 100px;
  }
  .header .mobile-menu-toggle {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .header {
    height: 70px;
    overflow: visible;
  }
  .header .header-menu {
    z-index: 997;
    position: fixed;
    top: 0;
    left: -100%;
    overflow-y: auto;
    background: white;
    width: 320px;
    max-width: 100%;
    height: 100%;
    -webkit-box-shadow: 0 0 36px 0 rgba(22, 25, 28, 0.17);
    box-shadow: 0 0 36px 0 rgba(22, 25, 28, 0.17);
    padding: 30px;
    -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .header .header-menu .nav {
    display: block;
  }
  .header .header-menu .nav .nav-item {
    margin-bottom: 8px;
  }
  .header .header-menu .nav .nav-item:last-child {
    margin-bottom: 0;
  }
  .header .header-menu .nav .nav-item .nav-link {
    display: inline-block;
    padding: 0;
  }
  .header .header-menu .nav .nav-item .nav-dropdown-toggle {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  .header .header-menu .nav .nav-item .nav-dropdown-toggle i {
    color: rgba(28, 29, 36, 0.7);
  }
  .header .header-menu .nav .nav-item .nav-dropdown-toggle:hover i,
  .header .header-menu .nav .nav-item .nav-dropdown-toggle.active i {
    color: #1c1d24;
  }
  .header .header-menu .nav .nav-item .nav-dropdown {
    display: none;
    padding: 8px 0 0 16px;
  }
  .header .header-menu .nav .nav-item .nav-dropdown.show {
    display: block;
  }
  .header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item {
    position: relative;
    margin-bottom: 8px;
  }
  .header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item.dropdown-icon-space {
    padding-right: 16px;
  }
  .header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item:last-child {
    margin-bottom: 0;
  }
  .header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item .nav-subdropdown {
    display: none;
    padding: 8px 0 0 16px;
  }
  .header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item .nav-subdropdown.show {
    display: block;
  }
  .header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item .nav-subdropdown .nav-subdropdown-item {
    margin-bottom: 8px;
  }
  .header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item .nav-subdropdown .nav-subdropdown-item:last-child {
    margin-bottom: 0;
  }
  .header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item .nav-subdropdown-toggle {
    top: 0;
    right: 0;
    height: 23px;
    line-height: 23px;
    cursor: pointer;
  }
  .header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item .nav-subdropdown-toggle i {
    color: rgba(28, 29, 36, 0.7);
  }
  .header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item .nav-subdropdown-toggle:hover i,
  .header .header-menu .nav .nav-item .nav-dropdown .nav-dropdown-item .nav-subdropdown-toggle.active i {
    color: #1c1d24;
  }
  .header .header-menu .nav .nav-item .nav-dropdown.style-2 .nav-dropdown-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
  }
  .header .header-menu .nav .nav-item.mega-nav-item .nav-megadropdown-toggle {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  .header .header-menu .nav .nav-item.mega-nav-item .nav-megadropdown-toggle i {
    color: rgba(28, 29, 36, 0.7);
  }
  .header .header-menu .nav .nav-item.mega-nav-item .nav-megadropdown-toggle:hover i,
  .header .header-menu .nav .nav-item.mega-nav-item .nav-megadropdown-toggle.active i {
    color: #1c1d24;
  }
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown {
    display: none;
    padding: 8px 0 0 16px;
  }
  .header .header-menu .nav .nav-item.mega-nav-item .mega-nav-dropdown.show {
    display: block;
  }
  .header.header-menu-show .header-menu {
    left: 0;
  }
  .header .mobile-menu-toggle {
    display: inline-block;
    position: relative;
    background: transparent;
    border: 0;
    margin-left: 24px;
    color: black;
    font-family: "Nunito", sans-serif;
  }
  .header .mobile-menu-toggle span {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 10px;
  }
  .header .mobile-menu-toggle span:before,
  .header .mobile-menu-toggle span:after {
    content: "";
    position: absolute;
    right: 0;
    background: black;
    height: 2px;
    border-radius: 2px;
    -webkit-transition: linear 0.1s;
    transition: linear 0.1s;
  }
  .header .mobile-menu-toggle span:before {
    top: 0;
    width: 70%;
  }
  .header .mobile-menu-toggle span:after {
    bottom: 0;
    width: 100%;
  }
  .header .mobile-menu-toggle:hover span:before {
    width: 100%;
  }
  .header .mobile-menu-toggle.toggle-close span:before {
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg);
    width: 100%;
  }
  .header .mobile-menu-toggle.toggle-close span:after {
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%) rotate(-45deg);
    transform: translateY(-50%) rotate(-45deg);
  }
  .header.transparent-light .mobile-menu-toggle,
  .header.header-dark:not(.transparent-dark) .mobile-menu-toggle {
    color: white;
  }
  .header.transparent-light .mobile-menu-toggle span:before,
  .header.transparent-light .mobile-menu-toggle span:after,
  .header.header-dark:not(.transparent-dark) .mobile-menu-toggle span:before,
  .header.header-dark:not(.transparent-dark) .mobile-menu-toggle span:after {
    background: white;
  }
}
.header-placeholder {
  height: 80px;
}
.header-placeholder-lg {
  height: 90px;
}
.header-placeholder-xl {
  height: 100px;
}
@media (max-width: 991.98px) {
  .header-placeholder,
  .header-placeholder-lg,
  .header-placeholder-xl {
    height: 70px;
  }
}
.portfolio-box {
  position: relative;
}
.portfolio-box a {
  display: block;
}
.portfolio-box .portfolio-img {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.portfolio-box .portfolio-img img {
  -webkit-transform: scale(1);
  transform: scale(1);
  width: 100%;
  -webkit-transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.portfolio-box:hover .portfolio-img {
  -webkit-transform: translate3d(0, 0, 0) scale(0.96);
  transform: translate3d(0, 0, 0) scale(0.96);
}
.portfolio-box:hover .portfolio-img img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.portfolio-box .portfolio-mobile-caption {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
}
.portfolio-box .portfolio-mobile-caption * {
  margin: 0;
}
@media (min-width: 992px) {
  .portfolio-box .portfolio-mobile-caption {
    display: none;
  }
}
.filter ul {
  padding: 0;
}
.filter ul li {
  z-index: 1;
  list-style-type: none;
  position: relative;
  display: inline-block;
  color: #1c1d24;
  margin-right: 20px;
  cursor: pointer;
  font-family: "Nunito", sans-serif;
  -webkit-transition: linear 0.1s;
  transition: linear 0.1s;
}
.filter ul li:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: -1px;
  left: 0;
  background: black;
  height: 1px;
  -webkit-animation: line-out 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) both;
  animation: line-out 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) both;
}
.filter ul li:hover,
.filter ul li.mixitup-control-active {
  color: black;
}
.filter ul li:hover:before,
.filter ul li.mixitup-control-active:before {
  width: 100%;
  -webkit-animation: line-in 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) both;
  animation: line-in 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) both;
}
.filter ul li:last-child {
  margin-right: 0;
}
[class*="bg-black"] .filter ul li,
[class*="bg-dark"] .filter ul li,
[class*="bg-gradient"] .filter ul li {
  color: white;
}
[class*="bg-black"] .filter ul li:before,
[class*="bg-dark"] .filter ul li:before,
[class*="bg-gradient"] .filter ul li:before {
  background: white;
}
.vertical-portfolio {
  position: relative;
  width: 100%;
  height: 100%;
}
.vertical-portfolio .vertical-portfolio-captions {
  height: 100%;
  max-height: 100%;
}
.vertical-portfolio .vertical-portfolio-captions ul {
  padding: 70px 0;
}
.vertical-portfolio .vertical-portfolio-captions ul li {
  list-style-type: none;
}
@media (min-width: 992px) {
  .vertical-portfolio .vertical-portfolio-captions {
    margin-left: calc(50% + 30px);
  }
  .vertical-portfolio .vertical-portfolio-captions ul li {
    margin-bottom: 40px;
  }
  .vertical-portfolio .vertical-portfolio-captions ul li:last-child {
    margin-bottom: 0;
  }
  .vertical-portfolio .vertical-portfolio-captions ul li img {
    position: fixed;
    top: 50%;
    right: calc(50% + 30px);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0;
    max-width: 40vw;
    margin-top: 20px;
    -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .vertical-portfolio .vertical-portfolio-captions ul li a {
    opacity: 0.5;
  }
  .vertical-portfolio .vertical-portfolio-captions ul li.active a,
  .vertical-portfolio .vertical-portfolio-captions ul li.active img {
    opacity: 1;
  }
  .vertical-portfolio .vertical-portfolio-captions ul li.active img {
    margin: 0;
  }
  .vertical-portfolio .vertical-portfolio-captions ul li.active .link-hover-line:before {
    width: 100%;
    -webkit-animation: line-in 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) both;
    animation: line-in 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) both;
  }
  .vertical-portfolio .vertical-portfolio-captions ul li.active .link-hover-gradient:before {
    width: 100%;
    -webkit-animation: line-in 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) both;
    animation: line-in 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) both;
  }
}
@media (max-width: 991.98px) {
  .vertical-portfolio .vertical-portfolio-captions {
    max-width: 70%;
    margin: 0 auto;
    text-align: center;
  }
  .vertical-portfolio .vertical-portfolio-captions ul {
    padding: 0;
  }
  .vertical-portfolio .vertical-portfolio-captions ul li {
    margin-bottom: 20px;
  }
  .vertical-portfolio .vertical-portfolio-captions ul li:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 767.98px) {
  .vertical-portfolio .vertical-portfolio-captions {
    max-width: 100%;
  }
}
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  visibility: visible;
  opacity: 1;
  background: white;
  width: 100%;
  height: 100%;
  text-align: center;
}
.preloader div {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
}
.preloader div span {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 1px solid;
  border-radius: 50%;
  border-color: #1c1d24 rgba(28, 29, 36, 0.2) #1c1d24 rgba(28, 29, 36, 0.2);
  -webkit-animation: loader-spin 600ms infinite linear;
  animation: loader-spin 600ms infinite linear;
}
.preloader.preloader-dark {
  background: black;
}
.preloader.preloader-dark div span {
  border-color: #fff rgba(255, 255, 255, 0.3) #fff rgba(255, 255, 255, 0.3);
}
.loaded .preloader {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}
@keyframes loader-spin {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.bg-dark {
  background: #1c1d24;
}
.bg-dark-90 {
  background: rgba(28, 29, 36, 0.9);
}
.bg-dark-80 {
  background: rgba(28, 29, 36, 0.8);
}
.bg-dark-70 {
  background: rgba(28, 29, 36, 0.7);
}
.bg-dark-60 {
  background: rgba(28, 29, 36, 0.6);
}
.bg-dark-50 {
  background: rgba(28, 29, 36, 0.5);
}
.bg-dark-40 {
  background: rgba(28, 29, 36, 0.4);
}
.bg-dark-30 {
  background: rgba(28, 29, 36, 0.3);
}
.bg-dark-20 {
  background: rgba(28, 29, 36, 0.2);
}
.bg-dark-10 {
  background: rgba(28, 29, 36, 0.1);
}
.bg-black {
  background: black;
}
.bg-black-90 {
  background: rgba(0, 0, 0, 0.9);
}
.bg-black-80 {
  background: rgba(0, 0, 0, 0.8);
}
.bg-black-70 {
  background: rgba(0, 0, 0, 0.7);
}
.bg-black-60 {
  background: rgba(0, 0, 0, 0.6);
}
.bg-black-50 {
  background: rgba(0, 0, 0, 0.5);
}
.bg-black-40 {
  background: rgba(0, 0, 0, 0.4);
}
.bg-black-30 {
  background: rgba(0, 0, 0, 0.3);
}
.bg-black-20 {
  background: rgba(0, 0, 0, 0.2);
}
.bg-black-10 {
  background: rgba(0, 0, 0, 0.1);
}
.bg-white {
  background: white;
}
.bg-white-90 {
  background: rgba(255, 255, 255, 0.9);
}
.bg-white-80 {
  background: rgba(255, 255, 255, 0.8);
}
.bg-white-70 {
  background: rgba(255, 255, 255, 0.7);
}
.bg-white-60 {
  background: rgba(255, 255, 255, 0.6);
}
.bg-white-50 {
  background: rgba(255, 255, 255, 0.5);
}
.bg-white-40 {
  background: rgba(255, 255, 255, 0.4);
}
.bg-white-30 {
  background: rgba(255, 255, 255, 0.3);
}
.bg-white-20 {
  background: rgba(255, 255, 255, 0.2);
}
.bg-white-10 {
  background: rgba(255, 255, 255, 0.1);
}
.bg-gray {
  background: #f1f2fa;
}
.bg-red {
  background: #e32227;
}
.bg-iceblue {
  background: #e8f3ff;
}
.bg-gradient-1,
.bg-gradient-2,
.bg-gradient-3,
.bg-gradient-4,
.bg-gradient-5 {
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.bg-gradient-1:before,
.bg-gradient-2:before,
.bg-gradient-3:before,
.bg-gradient-4:before,
.bg-gradient-5:before {
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bg-gradient-1:before {
  background-image: linear-gradient(45deg, #ff5988, #500c7f);
}
.bg-gradient-2:before {
  background-image: linear-gradient(45deg, #2fbdff, #0f2f40);
}
.bg-gradient-3:before {
  background-image: linear-gradient(45deg, #ff2f78, #ff7582);
}
.bg-gradient-4:before {
  background-image: linear-gradient(45deg, #00dbde, #fc00ff);
}
.bg-gradient-5:before {
  background-image: linear-gradient(45deg, #007990, #6ce5c0);
}
.bg-opacity-90:before {
  opacity: 0.9;
}
.bg-opacity-80:before {
  opacity: 0.8;
}
.bg-opacity-70:before {
  opacity: 0.7;
}
.bg-opacity-60:before {
  opacity: 0.6;
}
.bg-opacity-50:before {
  opacity: 0.5;
}
.bg-opacity-40:before {
  opacity: 0.4;
}
.bg-opacity-30:before {
  opacity: 0.3;
}
.bg-opacity-20:before {
  opacity: 0.2;
}
.bg-opacity-10:before {
  opacity: 0.1;
}
.font-family-primary {
  font-family: "Open Sans", sans-serif;
}
.font-family-secondary {
  font-family: "Nunito", sans-serif;
}
.font-family-tertiary {
  font-family: "Lora", serif;
}
.font-family-poppins {
  font-family: "Poppins", sans-serif;
}
.fw-medium {
  font-weight: 500;
}
.fw-semi-bold {
  font-weight: 600;
}
.letter-spacing-05 {
  letter-spacing: 0.5px;
}
.letter-spacing-1 {
  letter-spacing: 1px;
}
.letter-spacing-2 {
  letter-spacing: 2px;
}
.letter-spacing-3 {
  letter-spacing: 3px;
}
.letter-spacing-4 {
  letter-spacing: 4px;
}
.line-height-100 {
  line-height: 1;
}
.line-height-110 {
  line-height: 1.1;
}
.line-height-120 {
  line-height: 1.2;
}
.line-height-130 {
  line-height: 1.3;
}
.line-height-140 {
  line-height: 1.4;
}
[class*="bg-"] .text-white-90,
* .text-white-90 {
  color: rgba(255, 255, 255, 0.9);
}
[class*="bg-"] .text-white-80,
* .text-white-80 {
  color: rgba(255, 255, 255, 0.8);
}
[class*="bg-"] .text-white-70,
* .text-white-70 {
  color: rgba(255, 255, 255, 0.7);
}
[class*="bg-"] .text-white-60,
* .text-white-60 {
  color: rgba(255, 255, 255, 0.6);
}
[class*="bg-"] .text-white-50,
* .text-white-50 {
  color: rgba(255, 255, 255, 0.5);
}
[class*="bg-"] .text-white-40,
* .text-white-40 {
  color: rgba(255, 255, 255, 0.4);
}
[class*="bg-"] .text-white-30,
* .text-white-30 {
  color: rgba(255, 255, 255, 0.3);
}
[class*="bg-"] .text-white-20,
* .text-white-20 {
  color: rgba(255, 255, 255, 0.2);
}
[class*="bg-"] .text-white-10,
* .text-white-10 {
  color: rgba(255, 255, 255, 0.1);
}
[class*="bg-"] .text-black,
* .text-black {
  color: black;
}
[class*="bg-"] .text-black-90,
* .text-black-90 {
  color: rgba(0, 0, 0, 0.9);
}
[class*="bg-"] .text-black-80,
* .text-black-80 {
  color: rgba(0, 0, 0, 0.8);
}
[class*="bg-"] .text-black-70,
* .text-black-70 {
  color: rgba(0, 0, 0, 0.7);
}
[class*="bg-"] .text-black-60,
* .text-black-60 {
  color: rgba(0, 0, 0, 0.6);
}
[class*="bg-"] .text-black-50,
* .text-black-50 {
  color: rgba(0, 0, 0, 0.5);
}
[class*="bg-"] .text-black-40,
* .text-black-40 {
  color: rgba(0, 0, 0, 0.4);
}
[class*="bg-"] .text-black-30,
* .text-black-30 {
  color: rgba(0, 0, 0, 0.3);
}
[class*="bg-"] .text-black-20,
* .text-black-20 {
  color: rgba(0, 0, 0, 0.2);
}
[class*="bg-"] .text-black-10,
* .text-black-10 {
  color: rgba(0, 0, 0, 0.1);
}
[class*="bg-"] .text-primary,
* .text-primary {
  color: #787a7c;
}
[class*="bg-"] .text-green,
* .text-green {
  color: #32cd32;
}
[class*="bg-"] .text-red,
* .text-red {
  color: #e32227;
}
[class*="bg-"] .text-yellow,
* .text-yellow {
  color: #ffb657;
}
[class*="bg-"] .text-dark,
* .text-dark {
  color: #1c1d24;
}
[class*="bg-"] .text-dark-90,
* .text-dark-90 {
  color: rgba(28, 29, 36, 0.9);
}
[class*="bg-"] .text-dark-80,
* .text-dark-80 {
  color: rgba(28, 29, 36, 0.8);
}
[class*="bg-"] .text-dark-70,
* .text-dark-70 {
  color: rgba(28, 29, 36, 0.7);
}
[class*="bg-"] .text-dark-60,
* .text-dark-60 {
  color: rgba(28, 29, 36, 0.6);
}
[class*="bg-"] .text-dark-50,
* .text-dark-50 {
  color: rgba(28, 29, 36, 0.5);
}
[class*="bg-"] .text-dark-40,
* .text-dark-40 {
  color: rgba(28, 29, 36, 0.4);
}
[class*="bg-"] .text-dark-30,
* .text-dark-30 {
  color: rgba(28, 29, 36, 0.3);
}
[class*="bg-"] .text-dark-20,
* .text-dark-20 {
  color: rgba(28, 29, 36, 0.2);
}
[class*="bg-"] .text-dark-10,
* .text-dark-10 {
  color: rgba(28, 29, 36, 0.1);
}
.text-white * {
  color: white;
}
.text-white-90 * {
  color: rgba(255, 255, 255, 0.9);
}
.text-white-80 * {
  color: rgba(255, 255, 255, 0.8);
}
.text-white-70 * {
  color: rgba(255, 255, 255, 0.7);
}
.text-white-60 * {
  color: rgba(255, 255, 255, 0.6);
}
.text-white-50 * {
  color: rgba(255, 255, 255, 0.5);
}
.text-white-40 * {
  color: rgba(255, 255, 255, 0.4);
}
.text-white-30 * {
  color: rgba(255, 255, 255, 0.3);
}
.text-white-20 * {
  color: rgba(255, 255, 255, 0.2);
}
.text-white-10 * {
  color: rgba(255, 255, 255, 0.1);
}
[class*="text-gradient"] {
  display: inline-block;
}
.text-gradient-1 {
  background-image: linear-gradient(45deg, #ff5988, #500c7f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-gradient-2 {
  background-image: linear-gradient(45deg, #2fbdff, #0f2f40);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-gradient-3 {
  background-image: linear-gradient(45deg, #ff2f78, #ff7582);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-gradient-4 {
  background-image: linear-gradient(45deg, #00dbde, #fc00ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-gradient-5 {
  background-image: linear-gradient(45deg, #007990, #6ce5c0);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.stroke-text,
.stroke-text-dark {
  color: black;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1.4px;
  -webkit-text-stroke-color: black;
}
@media (max-width: 1199.98px) {
  .stroke-text,
  .stroke-text-dark {
    -webkit-text-stroke-width: 1.2px;
  }
}
.stroke-text-white {
  color: white;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: white;
}
[class*="bg-black"] .stroke-text,
[class*="bg-dark"] .stroke-text,
[class*="bg-gradient"] .stroke-text {
  color: white;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: white;
}
.border-dashed {
  border: 1px dashed rgba(28, 29, 36, 0.2);
}
.border-top-dashed {
  border-top: 1px dashed rgba(28, 29, 36, 0.2);
}
.border-right-dashed {
  border-right: 1px dashed rgba(28, 29, 36, 0.2);
}
.border-bottom-dashed {
  border-bottom: 1px dashed rgba(28, 29, 36, 0.2);
}
.border-left-dashed {
  border-left: 1px dashed rgba(28, 29, 36, 0.2);
}
[class*="bg-black"] .border-top-dashed,
[class*="bg-dark"] .border-top-dashed,
[class*="bg-gradient"] .border-top-dashed {
  border-color: rgba(255, 255, 255, 0.3);
}
[class*="bg-black"] .border-bottom-dashed,
[class*="bg-dark"] .border-bottom-dashed,
[class*="bg-gradient"] .border-bottom-dashed {
  border-color: rgba(255, 255, 255, 0.3);
}
.backdrop-filter-blur {
  backdrop-filter: blur(5px);
}
.n-margin-1 {
  margin-top: -1rem;
}
.n-margin-2 {
  margin-top: -2rem;
}
.n-margin-3 {
  margin-top: -3rem;
}
.n-margin-4 {
  margin-top: -4rem;
}
.n-margin-5 {
  margin-top: -5rem;
}
.n-margin-6 {
  margin-top: -6rem;
}
.z-index-1 {
  z-index: 1;
}
.z-index-2 {
  z-index: 2;
}
@media (min-width: 992px) {
  .fixed-lg-top {
    position: fixed;
    top: 0;
  }
  .fixed-lg-bottom {
    position: fixed;
    bottom: 0;
  }
}
.opacity-90 {
  opacity: 0.9;
}
.opacity-80 {
  opacity: 0.8;
}
.opacity-70 {
  opacity: 0.7;
}
.opacity-60 {
  opacity: 0.6;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-40 {
  opacity: 0.4;
}
.opacity-30 {
  opacity: 0.3;
}
.opacity-20 {
  opacity: 0.2;
}
.opacity-10 {
  opacity: 0.1;
}
.divider-side-line {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}
.divider-side-line:after {
  content: "";
  flex: 1 1 auto;
  background: rgba(28, 29, 36, 0.2);
  height: 1px;
}
