body {
  z-index: 0;
  background: transparent;
}

a {
  text-decoration: none !important;
}

body::before {
  content: "";
  position: absolute;
  top: -200px;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../assets/svg/trongdong.svg");
  filter: sepia(1) hue-rotate(180deg) saturate(10) brightness(0.7);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top;
  opacity: 0.05;
  height: 200%;
  z-index: -1;
  pointer-events: none;
}

.marquee-container {
  width: 100%; /* Chiều rộng của vùng nhìn thấy */
  overflow: hidden; /* Ẩn phần chữ ra khỏi container */
  white-space: nowrap; /* Chữ nằm trên một dòng */
}

.marquee {
  display: flex;
}

.marquee > h2 {
  padding-right: 70px;
}
.ml-0 {
  margin-left: 0 !important;
}
